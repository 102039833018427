import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {NotificationsService} from '../../services/notifications.service';
import * as _ from 'lodash';
import {SnackMessageService} from '../httpresponseinterceptor/snack-message-service';
import {MatMenuTrigger} from '@angular/material/menu';


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {

  @Input()
  drawer;

  @Input()
  showMenuTopBar;

  usuario: string;
  dominio: string;
  logo: string;
  loaded = false;
  notifications: any = [];
  notificationsSize: number = 0;
  isHiddeonContext;
  displayMenuMobile = false;

  constructor(private authService: AuthService,
              private notificationsService: NotificationsService,
              private snackMessageService: SnackMessageService
  ) {

    // setInterval(() => {
    //   this.resolveUpdatedNotifications();
    // }, 30000);
  }

  async ngOnInit() {
    let context = await this.authService.context();
    this.usuario = context.username;
    this.dominio = context.domain;
    await this.listNotifications();
    this.loaded = true;
    this.isHiddeonContext = this.checkRoutesPublics();
  }

  checkRoutesPublics() {
    return (window.location.pathname.includes('sampler') || window.location.pathname === '/' || window.location.pathname === '/login' || window.location.pathname === '/admin/login');
  }

  openMenuMobile(){
    this.displayMenuMobile = !this.displayMenuMobile;
  }

  async resolveUpdatedNotifications() {
    let length = this.notifications.length;
    let promise = await this.notificationsService.check(length);
    if (promise.modificado) {
      this.listNotifications();
    }
  }

  async listNotifications() {
    this.notifications = await this.notificationsService.getAll();
    let naolidas = this.notifications.filter(notitification => !notitification.lida);
    this.notificationsSize = naolidas.length;
  }


  async logout() {
    await this.authService.logout();
  }

  checkNotification(notificationSelected, evt) {
    evt.stopPropagation();
    this.notifications.map(notification => {
      if (notification.id === notificationSelected.id) {
        notification.lida = true;
      }
      this.notificationsService.markAsRead(notificationSelected.id);
      this.listNotifications();
    });
  }


  protected readonly _ = _;


  async markAllAsRead(evt) {
    const ids = this.notifications
      .map(notification => notification.id)
      .join(',');
    await this.notificationsService.markAllRead(ids).then(res => {
      this.snackMessageService.showSuccessMessage('Notificações marcadas com sucesso');
    });
    await this.listNotifications();

  }



}
