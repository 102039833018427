import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {LayoutProvador, Oculos, Pageable} from '../shared/models';


@Injectable({
  providedIn: 'root'
})
export class HistoricoDeContatoService {


  constructor(public http: HttpClient) {

  }

  async list(leadId) {
    var obj: any = {};
    obj.leadId = leadId;
    const response = await this.http.get<Pageable<any>>(environment.apiSiveonDev + '/historico-contato', {params: obj}).toPromise();
    return response;
  }

  async save(value: any) {
    const result = await this.http.post<any>(environment.apiSiveonDev + `/historico-contato`, value).toPromise();
    return result;
  }
}
