import {Component, Inject, OnInit} from '@angular/core';
import {OculosService} from '../../services/oculos.service';
import {FormBuilder, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TenantService} from '../../services/tenant.service';
import {ProvadorService} from '../../services/provador.service';

@Component({
  selector: 'provador-config-modal',
  templateUrl: './provador-config-modal.component.html',

})
export class ProvadorConfigModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) private data,
              private formBuilder: FormBuilder,
              private provadorService: ProvadorService
  ) {

  }

  loading: boolean = true;
  image: string = null;

  formProvador = this.formBuilder.group({
    logo: [''],
    id: [''],
    codigo: [''],
    parametroAjusteProvador: [''],
    widthLogo: [''],
    heightLogo: [''],
    marginLeftLogo: [''],
    marginTopLogo: [''],
    fontColorWelcome: [''],
    backgroundColorFirstCard: [''],
    text1: [''],
    boxShadowWelcome: [''],
    backgroundColorButtonAddImage: [''],
    fontColorButtonAddImage: [''],
    iconColorButtonAddImage: [''],
    iconColorReturn: [''],
    fontColorReturn: [''],
    fontColorTitleSecond: [''],
    backgroundColorSecondCard: [''],
    iconsInstrucaoColor: [''],
    fontColorAddToGalery: [''],
    backgroundColorAddToGalery: [''],
    colorIconGaleria: [''],
    fontColorAddToCamera: [''],
    backgroundColorAddToCamera: [''],
    colorIconCamera: [''],
    backgroundColorbuttonSend: [''],
    fontColorButtonSend: [''],
    iconColorButtonSend: [''],
    backgroundColorThirdCard: [''],
    backgroundColorReturnPhoto: [''],
    colorIconReturnPhoto: [''],
    fontColorReturnPhoto: [''],
    backgroundColorGoBuy: [''],
    colorIconGoBuy: [''],
    fontColorGoBuy: [''],
    linkBuyGlass: [''],
  });



  async ngOnInit() {
    let layoutProvadorByTenant = await this.provadorService.getLayoutProvadorByTenant(this.data.context.tenantId);
    if(layoutProvadorByTenant != null){
      this.formProvador.patchValue(layoutProvadorByTenant);
      this.formProvador.markAsUntouched();

    }
    this.loading = false;
  }

  async save() {
    // await this.tenantService.save(this.formProvador.value);
    this.close();
  }

  close() {
    this.dialogRef.close();
  }


}
