import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../services/auth.service';;
import {FormBuilder} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {Oculos, Pageable} from '../../shared/models';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {BreakpointObserver} from '@angular/cdk/layout';
import {DragdropUploadComponent} from '../../components/dragdropupload/dragdrop-upload.component';
import {Router} from '@angular/router';
import {PermissaoService} from '../../services/permissao.service';
import * as _ from 'lodash';
import {CustomFilterContentComponent} from '../../components/filter/custom-filter-content.component';
import {OculosModalComponent} from '../oculos/oculos-modal.component';
import { LeadsService } from 'src/services/leads.service';
import { LeadsModalComponent } from './modal/leads-modal.component';

enum SituacaoLeadsEnum {
  AGUARDANDO_CONTATO = 'Aguardando contato',
  PRIMEIRO_CONTATO = 'Primeiro contato',
  SEGUNDO_CONTATO = 'Segundo contato',
  DEMONSTRACAO_AGENDADA = 'Demonstração agendada',
  DEMONSTRACAO_REMARCADA = 'Demonstração remarcada',
  FECHADO_NA_DEMONSTRACAO='Fechado na demonstração',
  AGUARDANDO_FECHAR_POS_DEMOSTRACAO='Aguardando fechar apoś demonstração',
  FECHADO_POS_DEMOSTRACAO='Fechado apoś demonstração',
  PERDIDO='Perdido'
}


@Component({
  selector: 'leads-list',
  templateUrl: './leads-list.component.html',
  styleUrls: ['./leads-list.component.css']
})


export class LeadsListComponent implements OnInit {
  formFilter = this.formBuilder.group({
    'term': [''],
  });
  displayedColumns = ['nome', 'codigo', 'tenantSolicitacao', 'usuarioSolicitacao','status', 'edit'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  listOculos: any[] = [];
  page: Pageable<any>;
  term: string = '';
  loading: boolean = true;
  permissions = [];

  @ViewChild(CustomFilterContentComponent) customFilterContentComponent: CustomFilterContentComponent;

  constructor(private authService: AuthService,
              private formBuilder: FormBuilder,
              private changeDetectorRef: ChangeDetectorRef,
              private leadsService: LeadsService,
              private dialog: MatDialog,
              private breakpointObserver: BreakpointObserver,
              private router: Router,
              private permissionService: PermissaoService
  ) {
    this.searchEventEmitter.subscribe(async () => {
      this.list();
    })
  }

  filtroLead: any[] = [

    {
      descricao: 'Nome',
      value: 'nome',
      keys: ['nome'],
      type: 'STRING'
    },
    {
      descricao: "Situaçao Lead",
      value: "situacaoNome",
      keys: ['situacaoNome'],
      opcoes:  Object.keys(SituacaoLeadsEnum).map(key => ({value: key, description: SituacaoLeadsEnum[key]})),
      type:"ENUM"
    },



  ];

  order: any = {};


  async ngAfterViewInit() {
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 10;
    this.paginator.page.subscribe(() => this.list());

    await this.list();
    this.changeDetectorRef.detectChanges();


  }

  async list() {
    this.loading = true;
    const pageIndex = this.paginator.pageIndex;
    const pageSize = this.paginator.pageSize;
    this.page = await this.leadsService.list(pageIndex, pageSize, this.customFilterContentComponent.buildParamsFilter());
    this.dataSource.data = this.page.content;
    this.loading = false;
  }

  async search() {
    if (this.formFilter.get('term').value) {
      this.term = this.formFilter.get('term').value;
      this.paginator.pageIndex = 0;
    } else {
      this.term = '';
    }
    await this.list();
  }

  buildOptions(): {} {
    let order = {};
    if (this.order.value) {
      order[this.order.value] = this.order.order;
    }
    return {
      where: this.customFilterContentComponent.buildParamsFilter(),
      order: order,
    };
  }

  open(bean?) {
    const isMobile = this.breakpointObserver.isMatched('(max-width: 600px)');

    const dialogConfig = {
      width: isMobile ? '100%' : '800px',
      height: isMobile ? '100%' : '800px',
      data: {bean},
    };
    this.dialog.open(LeadsModalComponent, dialogConfig);


    this.dialog.afterAllClosed.subscribe(() => {
      this.list();
    });
  }




  searchEventEmitter: EventEmitter<any> = new EventEmitter();

  customOrder(newOrder) {
    if (this.order && this.order.value && this.order.value === newOrder) {
      this.order.order = (this.order.order === 'ASC') ? 'DESC' : 'ASC';
    } else {
      this.order = {
        value: newOrder,
        order: 'ASC'
      };
    }
    this.searchEventEmitter.emit();
  }


  async ngOnInit() {

  }



// ...



  protected readonly _ = _;
}
