import {Component, EventEmitter, HostListener, ViewChild} from '@angular/core';
import {ListOculosComponent} from '../../components/listagem-oculos/list-oculos.component';
import {Oculos} from '../../shared/models';
import {FormBuilder} from '@angular/forms';
import {StepperComponent} from '../../components/progress-bar/stepper.component';
import {Subscription} from 'rxjs';
import {ImageUploadService} from '../../services/image-upload.service';
import {MarkIrisImageComponent} from '../../components/mark-iris-image/mark-iris-image.component';
import {MedidasService} from '../../services/medidas.service';


@Component({
  selector: 'app-ferramenta medidas',
  templateUrl: './ferramenta-calibracao.component.html',
  styleUrls: ['./ferramenta-calibracao.component.css']
})
export class FerramentaCalibracaoComponent {

  oculosSelected: Oculos;
  currentStep: number = 0;
  @ViewChild('applistoculos') listoculos: ListOculosComponent;
  @ViewChild('steps') step: StepperComponent;
  @ViewChild('markiris') markiris: MarkIrisImageComponent;
  private imageUploadSubscription: Subscription;
  image: ImageSnippet;
  imageDisplay: string;
  searchEventEmitter = new EventEmitter<number>();
  media: string;

  constructor(private formBuilder: FormBuilder,
              private imageUploadService: ImageUploadService,
              private medidasService: MedidasService) {

    this.imageUploadSubscription = this.imageUploadService.image.subscribe(imageUrl => {
      this.image = imageUrl;
      if (imageUrl != null) {
        this.imageDisplay = imageUrl.src;
        this.currentStep = 2;
      }
    });


  }

  ngOnInit(): void {

  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
    this.imageUploadSubscription.unsubscribe();
    this.imageUploadService.removeImage();

  }


  selectedItem(evt) {
    this.oculosSelected = this.listoculos.value;
    if (this.oculosSelected != null) {
      this.currentStep = 1;
    }
  }


  changeSteps(evt) {
    this.currentStep = this.step.selectedStep;

  }

  async sendImage(evt) {
    this.currentStep = 3;
    this.media = this.markiris.media;


  }

}

class ImageSnippet {
  constructor(public src: string, public file: File) {
  }
}
