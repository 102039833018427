import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SnackMessageService} from 'src/components/httpresponseinterceptor/snack-message-service';
import {LeadsService} from 'src/services/leads.service';
import {HistoricoDeContatoService} from '../../../services/historicoDeContato.service';


@Component({
  selector: 'leads-modal-obs',
  templateUrl: './leads-modal-observacao.component.html',
  styleUrls: ['../leads-list.component.css']


})
export class LeadsModalObservacaoComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) private data,

  ) {

  }
  observacao = null;
  ngOnInit(): void {
    this.observacao = this.data.row.observacao;
  }

  close(){
    this.dialogRef.close();
  }


}
