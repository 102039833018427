import {Component, Input} from '@angular/core';
import {StepperSelectionEvent} from '@angular/cdk/stepper';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.css']

})
export class StepperComponent {
  @Input() selectedStep: number = 0
  @Input() steps = ['Step 1', 'Step 2', 'Step 3', 'Step 4'];


  onStepChanged(event: StepperSelectionEvent) {
    this.selectedStep = event.selectedIndex;
  }
}
