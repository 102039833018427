import {Component, Inject, OnInit} from '@angular/core';
import {OculosService} from '../../services/oculos.service';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {InclusaoOculosService} from '../../services/inclusao-oculos.service';
import {SnackMessageService} from '../../components/httpresponseinterceptor/snack-message-service';
import {PermissaoService} from '../../services/permissao.service';
import * as _ from 'lodash';
import {AuthService} from '../../services/auth.service';

enum StatusEnum {
  SOLICITADO = 'Solicitado a inclusão do óculos no sistema',
  DESENVOLVIMENTO = 'Óculos em produção',
  CORRECAO = 'Óculos em correção',
  FINALIZADO = 'Óculos disponível para uso'
}

enum StatusEnumSolicitacao {
  SOLICITADO = 'Solicitado a inclusão do óculos no sistema'

}

enum Marca {
  MORMAII = 'Mormaii',
}

@Component({
  selector: 'oculos-modal',
  templateUrl: './oculos-globais-modal.component.html',
  styleUrls: ['./oculos-globais-list.component.css']

})
export class OculosGlobaisModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<any>,
              private authService: AuthService,
              @Inject(MAT_DIALOG_DATA) private data,
              private formBuilder: FormBuilder,
              private oculosService: OculosService,
              private inclusaoOculosService: InclusaoOculosService,
              private snackService: SnackMessageService,
              private permissionService: PermissaoService
  ) {

  }


  loading: boolean = true;
  saving: boolean = false;
  loadingGlobalOculos: boolean = false;
  image: string = null;
  isAdmin: boolean = false;
  tenantId: number = null;
  arquivoSelecionado: File = null;
  statusEnumValues = [];
  statusEnumSolicitacaoValues = [];
  marca = [];
  oculosCadastrados: any = [];
  oculosSelecionadoClonagem: any;
  permissions = [];

  formOculos = this.formBuilder.group({
    'id': [''],
    'linkProduto': [''],
    'modelo': [{value: '', disabled: true}],
    'referenciaArmacao': [{value: '', disabled: true}],
    'codigoSiveon': [{value: '', disabled: true}],
    'larguraArmacao': [{value: '', disabled: true}],
    'alturaArmacao': [{value: '', disabled: true}],
    'marca': [{value: '', disabled: true}]

  });

  formlinkProvador = this.formBuilder.group({
    'linkProvador': [''],
  });

  async ngOnInit() {
    this.formOculos.markAsUntouched();
    if (this.data.bean != null) {
      this.formOculos.patchValue(this.data.bean);
      const image: any = await this.inclusaoOculosService.getImageOculosByCodigo(this.data.bean.codigoSiveon);

      if (image.content.length !== 0) {
        this.image = 'data:image/png;base64,' + image.content;
      }
      this.marca = Object.keys(Marca).map(key => ({value: key, description: Marca[key]}));
    }
    this.loading = false;
  }


  async cloneOculos() {
    this.loadingGlobalOculos = true;
    if (!_.isEmpty(this.oculosCadastrados)) {
      this.loadingGlobalOculos = false;
      return;
    }
    this.oculosService.getAll().then(result => {
      this.oculosCadastrados = result.content;
      this.loadingGlobalOculos = false;
    });

  }

  preencherFormOculos() {
    let oculos = this.oculosSelecionadoClonagem;
    this.formOculos.patchValue({
      'id': null,
      'linkProduto': null,
      'modelo': oculos.modelo,
      'codigoSiveon': oculos.codigoSiveon,
      'linkImage': oculos.linkImage,
      'larguraArmacao': oculos.larguraArmacao,
      'alturaArmacao': oculos.alturaArmacao,
      'situacaoProvador': oculos.situacaoProvador,
      'situacaoAreaMedidas': oculos.situacaoAreaMedidas,
      'tipoOculos': oculos.tipoOculos,
      'image': oculos.image,
      'possuiImagem': oculos.possuiImagem,
      'referenciaArmacao': oculos.referenciaArmacao,
    });
    this.getImageOculosByCodigo(oculos.codigoSiveon);
    this.formOculos.markAllAsTouched();
  }

  async save() {
    this.saving = true;
    await this.oculosService.cloneOculos(this.formOculos.get('codigoSiveon').value);
    this.saving = false;
    await this.close();
  }

  resolveParameters() {
    const formData = new FormData();
    formData.append('image', this.arquivoSelecionado);
    formData.append('oculosId', this.formOculos.get('id').value);
    return formData;
  }

  onArquivoSelecionadoChange(arquivo: File) {
    this.arquivoSelecionado = arquivo;
  }

  async getImageOculosByCodigo(codigo: string) {
    let image: any = await this.inclusaoOculosService.getImageOculosByCodigo(codigo);

    if (image.content.length != 0) {
      this.image = 'data:image/png;base64,' + image.content;
    }
  }

  close() {
    this.dialogRef.close();
  }

  copyLink() {
    let value: string = this.formlinkProvador.get('linkProvador').value;
    window.navigator['clipboard'].writeText(value).then(() => {

    });
  }

  protected readonly _ = _;
}
