import {Component, SecurityContext} from '@angular/core';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {OculosService} from '../../services/oculos.service';
import {PermissaoService} from '../../services/permissao.service';
import * as _ from 'lodash';
import {RelatoriosService} from '../../services/relatorios.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard-modal.component.html',
  styleUrls: ['./dashboard-modal.component.css']
})
export class DashboardModalComponent {
  permissions = [];
  loading = true;
  usuario: string = null;
  link: string = null;
  possuiDashboard = false;

  formLogin: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', Validators.required)
  });

  constructor(public route: Router,
              public service: AuthService,
              public oculosService: OculosService,
              public permissionService: PermissaoService,
              public relatoriosService: RelatoriosService,
              private sanitizer: DomSanitizer) {

  }


  async ngOnInit() {
    try {
      const dashboard = await this.relatoriosService.getDashboard();
      this.link = _.get(dashboard, 'url');
      this.possuiDashboard = true;
    } catch (e) {

    }

    this.loading = false;
  }

  photoURL() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.link);
  }

}
