import {Component, Inject, OnInit} from '@angular/core';
import {DialogOverviewExampleDialog} from '../areaMedidas/popup-image.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ColorPickerComponent} from '../../components/color-picker/color-picker.component';

@Component({
  selector: 'personaliza-list',
  templateUrl: './seleciona-valores-provador-modal.component.html',
  styleUrls: ['./personaliza-provador.component.css']
})
export class SelecionaValoresProvadorModalComponent implements OnInit {

  form: FormGroup;
  isFormLogo: boolean = false;
  isFormPrimeiraTela: boolean = false;
  isFormSegundaTela: boolean = false;
  isFormTerceiraTela: boolean = false;


  constructor(public dialogRef: MatDialogRef<SelecionaValoresProvadorModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data,
              private formBuilder: FormBuilder,
              private dialog: MatDialog,
  ) {}


  async ngOnInit() {

    this.form = this.data.form;

    this.resolveTypeForm();
  }

  resolveTypeForm() {
    switch (this.data.type) {
      case 'LOGO':
        this.isFormLogo = true;
        return;
      case 'PRIMEIRA_TELA':
        this.isFormPrimeiraTela = true;
        return;
      case 'SEGUNDA_TELA':
        this.isFormSegundaTela = true;
        return;
      case 'TERCEIRA_TELA':
        this.isFormTerceiraTela = true;
        return;
    }
  }


  fecharModal() {
      this.dialogRef.close(this.form);
  }

  openSelectedColorInput(fc: FormControl) {
    const dialogRef = this.dialog.open(ColorPickerComponent, {
      width: '400px',
      height: '500px',
      // position: {right: '0'}
    });

    dialogRef.afterClosed().subscribe(result => {
      fc.setValue(result);

    });

  }
}
