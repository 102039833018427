import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import * as _ from 'lodash';
import {PermissaoService} from '../../services/permissao.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RelatoriosService} from '../../services/relatorios.service';


@Component({
  selector: 'app-cards-informativos',
  templateUrl: './cards-informativos.component.html',
  styleUrls: ['./cards-informativos.component.css']
})
export class CardsInformativosComponent implements OnInit {

  title = 'SIVEON';
  permissions = [];
  isAdmin = false;
  totalAcessos = 0;


  constructor(private authService: AuthService,
              private permissionService: PermissaoService,
              private route: Router,
              private relatoriosService: RelatoriosService,
  ) {
  }

  async ngOnInit() {

  }

  async logout() {
    await this.authService.logout();
  }

  async openFerrmantaMeida() {
    await this.route.navigate(['/measurement-tool']);
  }

  async openCalibrarMedida() {
    await this.route.navigate(['/measurement-calibrate-tool']);
  }

  async openRelatorios() {
    await this.route.navigate(['/relatorios']);
  }

  async openListagemConfiguracaoOculos() {
    await this.route.navigate(['/oculos']);
  }

  async openDashboard() {
    await this.route.navigate(['/dashboard']);
  }

  async openTenants() {
    await this.route.navigate(['/home-revenda']);
  }


  async openSolicitacoes() {
    await this.route.navigate(['/solicitacoes']);
  }

  async openLeads() {
    await this.route.navigate(['/leads']);
  }


}
