import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {LayoutProvador, Pageable} from '../shared/models';

class GlassParameters {
  glassesCode: string;
  verticalAdjust: number;
  horizontalAdjustment: number;
  verticalAdjustEyewear: number;
  horizontalAdjustmentEyewear: number;
}

class Content {
  content: any;
}

@Injectable({
  providedIn: 'root'
})
export class ProvadorService {


  constructor(public http: HttpClient) {
  }

  parameters: any = {};

  sendimageSpring(body: any): Observable<any> {
    return null;
  }

  async list(offset: number = 1, limit: number = 10, filter: any) {
    const obj: any = {offset, limit, filter};
    const response = await this.http.get<Pageable<any>>(environment.apiSiveonDev + '/parametros/provador', {params: obj}).toPromise();
    return response;
  }

  async save(body) {
    return await this.http.post<Pageable<any>>(environment.apiSiveonDev + '/parametros/provador', body, {
      responseType: 'json',
      observe: 'response',
    }).toPromise();
  }

  sendimageApiSiveonTechnical(url: string, body: any, glasses: string, posicaoVertical: number, posicaoHorizontal: number, tamanhoVertical: number, tamanhoHorizontal: number): Observable<any> {
    let headers = new HttpHeaders();
    let params: any = {};
    params = this.resolveParametersTechinal(glasses, posicaoVertical, posicaoHorizontal, tamanhoVertical, tamanhoHorizontal);
    return this.http.post(url, body, {headers: headers, params: params}).pipe(map(data => {
      return data;
    }));
  }

  resolveParametersTechinal(glasses: string, posicaoVertical: number, posicaoHorizontal: number, tamanhoVertical: number, tamanhoHorizontal: number) {
    switch (glasses) {
      case 'regua':
        this.parameters.glassCode = 'a';
        this.parameters.verticalAdjust = posicaoVertical;
        this.parameters.horizontalAdjustment = posicaoHorizontal;
        this.parameters.verticalAdjustEyewear = tamanhoVertical;
        this.parameters.horizontalAdjustmentEyewear = tamanhoHorizontal;
        return this.parameters;
        break;
    }
  }


  async getParameterAdjusteByUuid(codigo, domain) {
    let parameter: any = {};
    parameter.codigo = codigo;
    parameter.domain = domain;
    const response = await this.http.get<Content>(environment.apiSiveonDev + '/api/siveon/provador/parametro', {params: parameter}).toPromise();
    return response.content;
  }

  async getLayoutProvador(domain) {
    let parameter: any = {};
    parameter.domain = domain;
    const response = await this.http.get<LayoutProvador>(environment.apiSiveonDev + '/parametros/provador/layout', {params: parameter}).toPromise();
    return response;
  }

  async getLinkRedicionamento(domain, codigo) {
    let parameter: any = {};
    parameter.domain = domain;
    parameter.codigo = codigo;
    const response = await this.http.get<any>(environment.apiSiveonDev + '/parametros/provador/link', {params: parameter}).toPromise();
    return response;
  }

  async getLayoutProvadorByTenant(tenantId) {
    let parameter: any = {};
    parameter.tenant = tenantId;
    const response = await this.http.get<any>(environment.apiSiveonDev + '/parametros/provador/layout/tenant', {params: parameter}).toPromise();
    return response;
  }

  async saveLayoutProvadorByTenant(tenantId, body) {
    let parameter: any = {};
    parameter.tenant = tenantId;
    const response = await this.http.post<any>(environment.apiSiveonDev + '/parametros/provador/tenant/' + tenantId, body).toPromise();
    return response;
  }

  async saveLayoutProvador(body) {
    const response = await this.http.post<any>(environment.apiSiveonDev + '/parametros/provador', body).toPromise();
    return response;
  }


  async checkIsAtivo(domain, codigo) {
    let parameter: any = {};
    parameter.codigo = codigo;
    parameter.domain = domain;
    const response = await this.http.get<Content>(environment.apiSiveonDev + '/oculos/ativo', {params: parameter}).toPromise();
    return response.content;
  }

  async incrementAcesso(codigo, domain) {
    let parameter: any = {};
    parameter.domain = domain;
    parameter.codigo = codigo;
    if (domain && codigo != null) {
      await this.http.get<any>(environment.apiSiveonDev + '/api/siveon/provador/acesso', {params: parameter}).toPromise();
    }


  }


  sendImageProvadorSiveon(url: string, body: any): Observable<any> {
    return this.http.post(url, body, {observe: 'response'}).pipe(
      tap(response => {
        // Você pode armazenar ou usar o status da resposta conforme necessário
      }),
      map(response => response.body),
      catchError(error => {
        return throwError(error);
      })
    );
  }


}
