import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {LayoutProvador, Oculos, Pageable} from '../shared/models';


@Injectable({
  providedIn: 'root'
})
export class OculosService {


  constructor(public http: HttpClient) {

  }

  async list(offset: number = 1, limit: number = 10, filter: any) {
    const obj: any = {offset, limit, filter};
    const response = await this.http.get<Pageable<any>>(environment.apiSiveonDev + '/oculos', {params: obj}).toPromise();
    return response;
  }

  async listSolicitacao(offset: number = 1, limit: number = 10, filter: any) {
    const obj: any = {offset, limit, filter};
    const response = await this.http.get<Pageable<any>>(environment.apiSiveonDev + '/oculos/solicitacoes', {params: obj}).toPromise();
    return response;
  }


  async listOculosGlobais(offset: number = 1, limit: number = 10, filter: any) {
    const obj: any = {offset, limit, filter};
    const response = await this.http.get<Pageable<any>>(environment.apiSiveonDev + '/oculos/all', {params: obj}).toPromise();
    return response;
  }

  async getAll() {
    const response = await this.http.get<any>(environment.apiSiveonDev + '/oculos/all').toPromise();
    return response;
  }


  async listAdmin(page: number = 1, size: number = 10, filter: string, tenantId: number) {
    const obj: any = {page, size, filter};
    const response = await this.http.get<Pageable<any>>(environment.apiSiveonDev + '/oculos/' + tenantId + '/tenant', {params: obj}).toPromise();
    return response;
  }

  async get(id: number) {
    const response = await this.http.get<Oculos>(environment.apiSiveonDev + '/oculos/' + id,).toPromise();
    return response;
  }

  async getQuantidadeDisponivel() {
    const response = await this.http.get<any>(environment.apiSiveonDev + '/oculos/quantidade-disponivel').toPromise();
    return response;
  }

  async getImage(glassCode: string, tamanhoVertical: string, tamanhoHorizontal: string) {
    let parameters: any = {};
    parameters.glassCode = glassCode;
    parameters.alturaArmacao = tamanhoVertical;
    parameters.larguraArmacao = tamanhoHorizontal;
    const response = await this.http.get<any>(environment.apiSiveonDev + '/oculos/image', {params: parameters}).toPromise();
    return response;
  }


  async updateFatorConversaoOculos(id: number, fatorConversaoAltura, fatorConversaoLargura) {
    let parameters: any = {};
    parameters.fatorConversaoAltura = fatorConversaoAltura;
    parameters.fatorConversaoLargura = fatorConversaoLargura;
    const response = await this.http.post<Oculos>(environment.apiSiveonDev + '/oculos/' + id + '/parametro', {}, {params: parameters}).toPromise();
    return response;
  }


  async save(value: any) {
    const result = await this.http.post<Oculos>(environment.apiSiveonDev + `/oculos`, value).toPromise();
    return result;
  }

  async cloneOculos(codigoSiveon: string) {
    const result = await this.http.post<Oculos>(environment.apiSiveonDev + `/oculos/clone/` + codigoSiveon, null).toPromise();
    return result;
  }

  async saveAdminValues(body: any, tenantId: number,) {
    const headers = new HttpHeaders();


    const result = await this.http.post<Oculos>(environment.apiSiveonDev + '/oculos/tenant/' + tenantId, body, {headers: headers}).toPromise();
    return result;
  }

  async saveAdminImagem(body: any, tenantId: number,) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    const result = await this.http.post<Oculos>(environment.apiSiveonDev + '/oculos/tenant/' + tenantId + '/image', body, {headers: headers}).toPromise();
    return result;
  }

  async saveImagem(body: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    const result = await this.http.post<Oculos>(environment.apiSiveonDev + '/oculos/image', body, {headers: headers}).toPromise();
    return result;
  }

  // async save(body) {
  //   const headers = new HttpHeaders();
  //   headers.append('Content-Type', 'multipart/form-data');
  //   const response = await this.http.post(environment.apiSiveonDev + '/solicitacao/upload', body, {headers:headers}).toPromise();
  //   return response;
  // }
}
