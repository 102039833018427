import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {HttpResponseInterceptorComponent} from './http-response-interceptor.component';
import {AuthService} from '../../services/auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackMessageService} from './snack-message-service';


@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  requestNotUsingInterceptors: string[] =
    ['/api/siveon/medidas/move',
      '/core/context',
      'permissoes',
      '/api/siveon/provador/parametro',
      '/parametros/provador/layout',
      '/api/siveon/provador/acesso',
      '/api/siveon/medidas/resize',
      '/oculos/image'];

  constructor(private dialog: MatDialog,
              private authService: AuthService,
              private snackService: SnackMessageService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (request.method === 'POST') {
      return next.handle(request).pipe(
        tap(response => {
          if (response.type === HttpEventType.Response) {
            this.snackService.showSuccessMessage('Operação realizada com sucesso!');
          }
        }),
        catchError(async (error: any) => {
          this.snackService.showErrorMessage(error.error);
          if (error.status === 403) {
            await this.authService.logout();
          }
          return throwError(error);
        })
      );
    }else {
      // Se não for POST, apenas passar a requisição adiante
      return next.handle(request);
    }

  }

  checkNeedIntercept(request: HttpRequest<any>): boolean {
    const url = new URL(request.url);
    const pathname = url.pathname;
    return this.requestNotUsingInterceptors.indexOf(pathname) !== -1;


  }


}

