import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PermissaoService {
  private cachedPermissions: string[] | null = null;
  constructor(public http: HttpClient) {
  }


  async fetchPermissionsFromBackend() {
    const response = await this.http.get<any>(environment.apiSiveonDev + '/permissoes',).toPromise();
    return response;
  }

  async getAllPermissionsByUser(userId) {
    const response = await this.http.get<any>(environment.apiSiveonDev + '/permissoes/all-user/' + userId ,).toPromise();
    return response;
  }

  async addPermissao(userId,value) {
    const response = await this.http.post<any>(environment.apiSiveonDev + '/permissoes/' + userId + '?acao=' +value ,  {}).toPromise();
    return response;
  }

  async removePermissao(userId,value) {
    const response = await this.http.post<any>(environment.apiSiveonDev + '/permissoes/' + userId +'/remove?acao=' +value ,  {}).toPromise();
    return response;
  }


  async getPermissoes(): Promise<string[]> {
    if (this.cachedPermissions) {
      return this.cachedPermissions;
    } else {
      // Realize a chamada ao backend para obter as permissões
      const permissions = await this.fetchPermissionsFromBackend();
      this.cachedPermissions = permissions;
      return permissions;
    }
  }


}
