import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {SnackMessageService} from '../../components/httpresponseinterceptor/snack-message-service';


@Component({
  selector: 'app-login',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.css']
})
export class LoginModalComponent {

  formLogin: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', Validators.required)
  });

  constructor(public route: Router,
              public service: AuthService,
              public snackMessageService: SnackMessageService) {

  }

  isAdmin: boolean = false

  ngOnInit(): void {
    if(window.location.pathname.includes('admin')){
      this.isAdmin = true;
    }

  }


  async login() {

    if (this.isAdmin) {
      try {
        await this.service.loginAdmin(this.formLogin.value)
        this.snackMessageService.showSuccessMessage("login efetuado com sucesso!")
        return await this.route.navigate(['/home-revenda']);
      }catch (error){
        this.snackMessageService.showErrorMessage("usuario ou senha invalidos!")
      }

    }

    try {
      await this.service.login(this.formLogin.value);
      this.snackMessageService.showSuccessMessage("login efetuado com sucesso!")
      return await this.route.navigate(['/home-lojista']);
    }catch (e){
      this.snackMessageService.showErrorMessage("usuario ou senha invalidos!")
    }



  }
}
