import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import * as _ from 'lodash';
import {SnackMessageService} from 'src/components/httpresponseinterceptor/snack-message-service';
import {LeadsService} from 'src/services/leads.service';
import {HistoricoDeContatoService} from '../../../services/historicoDeContato.service';
import {MatTableDataSource} from '@angular/material/table';
import {LeadsModalObservacaoComponent} from './leads-modal-observacao.component';

enum SituacaoLeadsEnum {
  AGUARDANDO_CONTATO = 'Aguardando contato',
  PRIMEIRO_CONTATO = 'Primeiro contato',
  SEGUNDO_CONTATO = 'Segundo contato',
  DEMONSTRACAO_AGENDADA = 'Demonstração agendada',
  DEMONSTRACAO_REMARCADA = 'Demonstração remarcada',
  FECHADO_NA_DEMONSTRACAO = 'Fechado na demonstração',
  AGUARDANDO_FECHAR_POS_DEMOSTRACAO = 'Aguardando fechar apoś demonstração',
  FECHADO_POS_DEMOSTRACAO = 'Fechado apoś demonstração',
  PERDIDO = 'Perdido'
}


@Component({
  selector: 'leads-modal',
  templateUrl: './leads-modal.component.html',
  styleUrls: ['../leads-list.component.css']


})
export class LeadsModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) private data,
              private formBuilder: FormBuilder,
              private snackService: SnackMessageService,
              private leadService: LeadsService,
              private historicoDeContatoService: HistoricoDeContatoService,
              private dialog: MatDialog,
  ) {

  }

  displayedColumns = ['nome','situacao','observacao','data','edit'];
  dataSource = new MatTableDataSource<any>();
  loading: boolean = true;
  saving: boolean = false;
  situacaoLeadsEnumValues = [];
  historico: any = null;

  formLeads = this.formBuilder.group({
    'id': [''],
    'nome': [''],
    'situacao': [''],
    'endereco': [''],
    'feedback': [''],
    'website': [''],
    'telefone': [''],
    'contato': [''],
  });
  formHistorico = this.formBuilder.group({
    'id': [''],
    'observacao': [''],
    'situacao': [''],
    'lead': [''],

  });


  async ngOnInit() {
    this.formLeads.patchValue(this.data.bean);
    this.situacaoLeadsEnumValues = Object.keys(SituacaoLeadsEnum).map(key => ({value: key, description: SituacaoLeadsEnum[key]}));
    if (this.data.bean.id !== null) {
       await this.historicoDeContatoService.list((this.data.bean.id)).then(r =>{
         this.dataSource.data = r.content;
      });
    }
    this.loading = false;

  }

  async save() {
    this.saving = true;
    await this.leadService.save(this.formLeads.value);
    this.formHistorico.get('lead').setValue(this.formLeads.value);
    await this.historicoDeContatoService.save(this.formHistorico.value);
    this.snackService.showSuccessMessage('informações salvas com sucesso!');
    this.ngOnInit();
    this.saving = false;

  }


  async close() {
    this.dialogRef.close();
  }

  visualizarObservacao(row:any){
    const dialogConfig = {
      width:  '400px',
      height:  '200px',
      data: {row},
    };
    this.dialog.open(LeadsModalObservacaoComponent, dialogConfig);



  }


  protected readonly _ = _;
}
