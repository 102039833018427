import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleForms {

  constructor(public http: HttpClient) {
  }


  sendResponse(body: any): Observable<any> {
    let headers = new HttpHeaders();
    headers.append('Content-Type','text/html; charset=utf-8')
    return this.http.post('https://docs.google.com/forms/u/0/d/e/1FAIpQLSfrCnxMQAl0NNhFyl70x68dVNEtVVIvukK4u5ara_oo0NugDg/formResponse', body,{headers:headers}).pipe(map(data => {
      return data;
    }));
  }


}
