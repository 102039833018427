import {Injectable} from '@angular/core';
import {Provador} from './provador';

@Injectable({
  providedIn: 'root'
})
export class ProvadorBuilderService {
  private codigoOculos: string;
  private logo: string;
  private corDeFundo: string;
  private corTituloBemVindo: string;
  private corCardExterno: string;
  private corCardInterno: string;
  private corSubtitulo: string;
  private corInstrucoes: string;
  private corIconesInstrucoes: string;
  private corFundoBotaoAdicionarImagem: string;
  private corTextoAdicionarImagem: string;
  private corIconeAdicionarImagem: string;
  private corTextoRetornarProduto: string;
  private corIconeRetornarProduto: string;
  private corFundoBotaoEnviarImagem: string;
  private corTextoEnviarImagem: string;
  private corIconeEnviarImagem: string;
  private corFundoBotaoNovaImagem: string;
  private corTextoNovaImagem: string;
  private corIconeNovaImagem: string;
  private corFundoBotaoContinuarCompra: string;
  private corTextoContinuarCompra: string;
  private corIconeContinuarCompra: string;
  private linkRedirecionamento: string;

  constructor() {
  }

  withLogo(logo: string): ProvadorBuilderService {
    this.logo = logo;
    return this;
  }

  withCorDeFundo(corDeFundo: string): ProvadorBuilderService {
    this.corDeFundo = corDeFundo;
    return this;
  }

  withCorTituloBemVindo(corTituloBemVindo: string): ProvadorBuilderService {
    this.corTituloBemVindo = corTituloBemVindo;
    return this;
  }

  withCorCardExterno(corCardExterno: string): ProvadorBuilderService {
    this.corCardExterno = corCardExterno;
    return this;
  }

  withCorCardInterno(corCardInterno: string): ProvadorBuilderService {
    this.corCardInterno = corCardInterno;
    return this;
  }

  withCorSubtitulo(corSubtitulo: string): ProvadorBuilderService {
    this.corSubtitulo = corSubtitulo;
    return this;
  }

  withCorInstrucoes(corInstrucoes: string): ProvadorBuilderService {
    this.corInstrucoes = corInstrucoes;
    return this;
  }

  withCorIconesInstrucoes(corIconesInstrucoes: string): ProvadorBuilderService {
    this.corIconesInstrucoes = corIconesInstrucoes;
    return this;
  }

  withCorFundoBotaoAdicionarImagem(corFundoBotaoAdicionarImagem: string): ProvadorBuilderService {
    this.corFundoBotaoAdicionarImagem = corFundoBotaoAdicionarImagem;
    return this;
  }

  withCorTextoAdicionarImagem(corTextoAdicionarImagem: string): ProvadorBuilderService {
    this.corTextoAdicionarImagem = corTextoAdicionarImagem;
    return this;
  }

  withCorIconeAdicionarImagem(corIconeAdicionarImagem: string): ProvadorBuilderService {
    this.corIconeAdicionarImagem = corIconeAdicionarImagem;
    return this;
  }

  withCorTextoRetornarProduto(corTextoRetornarProduto: string): ProvadorBuilderService {
    this.corTextoRetornarProduto = corTextoRetornarProduto;
    return this;
  }

  withCorIconeRetornarProduto(corIconeRetornarProduto: string): ProvadorBuilderService {
    this.corIconeRetornarProduto = corIconeRetornarProduto;
    return this;
  }

  withCorFundoBotaoEnviarImagem(corFundoBotaoEnviarImagem: string): ProvadorBuilderService {
    this.corFundoBotaoEnviarImagem = corFundoBotaoEnviarImagem;
    return this;
  }

  withCorTextoEnviarImagem(corTextoEnviarImagem: string): ProvadorBuilderService {
    this.corTextoEnviarImagem = corTextoEnviarImagem;
    return this;
  }

  withCorIconeEnviarImagem(corIconeEnviarImagem: string): ProvadorBuilderService {
    this.corIconeEnviarImagem = corIconeEnviarImagem;
    return this;
  }

  withCodigoOculos(codigoOculos: string): ProvadorBuilderService {
    this.codigoOculos = codigoOculos;
    return this;
  }

  withCorTextoNovaImagem(corTextoNovaImagem: string): ProvadorBuilderService {
    this.corTextoNovaImagem = corTextoNovaImagem;
    return this;
  }

  withCorFundoBotaoNovaImagem(corFundoBotaoNovaImagem: string): ProvadorBuilderService {
    this.corFundoBotaoNovaImagem = corFundoBotaoNovaImagem;
    return this;
  }

  withcorIconeNovaImagem(corIconeNovaImagem: string): ProvadorBuilderService {
    this.corIconeNovaImagem = corIconeNovaImagem;
    return this;
  }

  withCorTextoContinuarCompra(corTextoContinuarCompra: string): ProvadorBuilderService {
    this.corTextoContinuarCompra = corTextoContinuarCompra;
    return this;
  }

  withCorFundoBotaoContinuarCompra(corFundoBotaoContinuarCompra: string): ProvadorBuilderService {
    this.corFundoBotaoContinuarCompra = corFundoBotaoContinuarCompra;
    return this;
  }

  withcorIconeContinuarCompra(corIconeContinuarCompra: string): ProvadorBuilderService {
    this.corIconeContinuarCompra = corIconeContinuarCompra;
    return this;
  }
  withLinkRedirecionamento(linkRedirecionamento: string): ProvadorBuilderService {
    this.linkRedirecionamento = linkRedirecionamento;
    return this;
  }

  build(): Provador {
    return new Provador(
      this.logo,
      this.codigoOculos,
      this.corDeFundo,
      this.corTituloBemVindo,
      this.corCardExterno,
      this.corCardInterno,
      this.corSubtitulo,
      this.corInstrucoes,
      this.corIconesInstrucoes,
      this.corFundoBotaoAdicionarImagem,
      this.corTextoAdicionarImagem,
      this.corIconeAdicionarImagem,
      this.corTextoRetornarProduto,
      this.corIconeRetornarProduto,
      this.corFundoBotaoEnviarImagem,
      this.corTextoEnviarImagem,
      this.corIconeEnviarImagem,
      this.corFundoBotaoNovaImagem,
      this.corTextoNovaImagem,
      this.corIconeNovaImagem,
      this.corFundoBotaoContinuarCompra,
      this.corTextoContinuarCompra,
      this.corIconeContinuarCompra,
      this.linkRedirecionamento
    );
  }
}

