import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {GoogleForms} from '../../services/google-forms-contato.service';
import {environment} from '../../environments/environment';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {Router} from '@angular/router';

@Component({
  selector: 'app-banner-desktop',
  templateUrl: './home-desktop.component.html',
  styleUrls: ['./home-desktop.component.css']
})
export class HomeDesktopComponent implements OnInit {
  isHeading = true;
  isSubheading = true;
  isHeadingBtn = true;
  linkWhats: string = 'https://wa.me/5548996310914?text=';
  messagePadraoSolucao: string = 'olá, quero saber mais sobre as soluções';
  messagePadraoPlano = 'olá, quero saber mais sobre os planos';
  @ViewChild('solucao') solucao: ElementRef;
  itensMenu: any[] = [
    {
      descricao: 'Soluções',
      value: this.solucao
    },
    {
      descricao: 'Sobre',
      value: null
    },
    {
      descricao: 'Contato',
      value: null
    },
  ];

  constructor(private googleForms: GoogleForms,
              private formBuilder: FormBuilder,
              private routeServide: Router) {
  }

  formFilter = this.formBuilder.group({
    nome: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    telefone: new FormControl('', Validators.required),
    empresa: new FormControl('',),
    message: new FormControl('',),
  });

  ngOnInit(): void {
  }

  scrollToElement(element: HTMLElement) {
    element.scrollIntoView();
  }

  teste() {
    const formData = new FormData();
    formData.append('entry.425819325', 'entry.425819325');
    formData.append('entry.1565124081 ', 'entry.1565124081');
    formData.append(' entry.936593139', '936593139');
    formData.append('entry.513786755 ', '513786755');
    const body = formData;

    this.googleForms.sendResponse(null)
      .toPromise()
      .then(response => {

      });
  }

  sendFormulario() {
    window.open(this.linkWhats + 'nome: ' + this.formFilter.value.nome
      + ', email: ' + this.formFilter.value.email
      + ', telefone: ' + this.formFilter.value.telefone
      + ', empresa: ' + this.formFilter.value.empresa
      + ', mensagem: ' + this.formFilter.value.message

      , '_blank');
    this.formFilter.value.nome;
  }

  gotoWhatsSolucao() {
    window.open(this.linkWhats + this.messagePadraoSolucao, '_blank');

  }

  gotoWhatsPlanos() {
    window.open(this.linkWhats + this.messagePadraoPlano, '_blank');

  }


}
