import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ProvadorService} from '../../services/provador.service';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {GoogleForms} from '../../services/google-forms-contato.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  isHeading = true;
  isSubheading = true;
  isHeadingBtn = true;
  linkWhats: string = 'https://wa.me/5548996310914?text=';
  messagePadraoSolucao: string = 'olá, quero saber mais sobre as soluções';
  messagePadraoPlano = 'olá, quero saber mais sobre os planos';

  constructor(private googleForms: GoogleForms,
              private formBuilder: FormBuilder,
              private routeServide: Router) {
  }


  formFilter = this.formBuilder.group({
    nome: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    telefone: new FormControl('', Validators.required),
    empresa: new FormControl('',),
    message: new FormControl('',),
  });

  async ngOnInit() {

  }


  open() {
  }

  scrollToElement(element: HTMLElement) {
    element.scrollIntoView();
  }



  sendFormulario() {
    window.open(this.linkWhats + 'nome: ' + this.formFilter.value.nome
      + ', email: ' + this.formFilter.value.email
      + ', telefone: ' + this.formFilter.value.telefone
      + ', empresa: ' + this.formFilter.value.empresa
      + ', mensagem: ' + this.formFilter.value.message

      , '_blank');
    this.formFilter.value.nome;
  }

  gotoWhatsSolucao() {
    window.open(this.linkWhats + this.messagePadraoSolucao, '_blank');
  }

  gotoWhatsPlanos() {
    window.open(this.linkWhats + this.messagePadraoPlano, '_blank');
  }



}
