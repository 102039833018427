import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {LayoutProvador, Oculos, Pageable} from '../shared/models';


@Injectable({
  providedIn: 'root'
})
export class RelatoriosService {

  constructor(public http: HttpClient) {
  }


  async list(offset: number = 1, limit: number = 10, filter: any) {
    const obj: any = {offset, limit, filter};
    const response = await this.http.get<Pageable<any>>(environment.apiSiveonDev + '/relatorios', {params: obj}).toPromise();
    return response;
  }

  async save(body) {
    return await this.http.post<Pageable<any>>(environment.apiSiveonDev + '/relatorios', body, {
      responseType: 'json',
      observe: 'response',
    }).toPromise();
  }

  async getDashboard() {
    return await this.http.get<Pageable<any>>(environment.apiSiveonDev + '/relatorios/dashboard').toPromise();
  }


}
