import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, ViewChild} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {FormBuilder} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {CustomFilterContentComponent} from '../../components/filter/custom-filter-content.component';
import {OculosService} from '../../services/oculos.service';
import {OculosGlobaisModalComponent} from './oculos-globais-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {BreakpointObserver} from '@angular/cdk/layout';
import {PermissaoService} from '../../services/permissao.service';
import * as _ from 'lodash';

enum StatusEnum {
  SOLICITADO = 'Solicitado a inclusão do óculos no sistema',
  DESENVOLVIMENTO = 'Óculos em produção',
  CORRECAO = 'Óculos em correção',
  FINALIZADO = 'Óculos disponível para uso'
}

@Component({
  selector: 'oculos-globais-inclusao-list',
  templateUrl: './oculos-globais-list.component.html',
  styleUrls: ['./oculos-globais-list.component.css']
})
export class OculosGlobaisListComponent implements AfterViewInit {
  formFilter = this.formBuilder.group({
    'term': [''],
  });
  loading = false;
  searchEventEmitter: EventEmitter<any> = new EventEmitter();
  term: string = '';
  totalElements: any = null;
  filtroOculos: any[] = [
    {
      descricao: 'Nome',
      value: 'modelo',
      keys: ['modelo'],
      type: 'STRING'
    },

    {
      descricao: 'Codigo',
      value: 'codigoSiveon',
      keys: ['codigoSiveon'],
      type: 'STRING'
    },

    {
      descricao: 'Situaçao Provador',
      value: 'situacaoProvador',
      keys: ['situacaoProvador'],
      opcoes: Object.keys(StatusEnum).map(key => ({value: key, description: StatusEnum[key]})),
      type: 'ENUM'
    },
    {
      descricao: 'Situaçao Area de medidas',
      value: 'situacaoAreaMedidas',
      keys: ['situacaoAreaMedidas'],
      opcoes: Object.keys(StatusEnum).map(key => ({value: key, description: StatusEnum[key]})),
      type: 'ENUM'
    },
  ];

  @ViewChild(CustomFilterContentComponent) customFilterContentComponent: CustomFilterContentComponent;

  constructor(private authService: AuthService,
              private formBuilder: FormBuilder,
              private changeDetectorRef: ChangeDetectorRef,
              private breakpointObserver: BreakpointObserver,
              private oculosService: OculosService,
              private dialog: MatDialog,
              private permissionService: PermissaoService
  ) {
    this.searchEventEmitter.subscribe(async () => {
      this.list();
    });
  }

  displayedColumns = ['modelo', 'codigo', 'marca', 'visualizar'];
  dataSource = new MatTableDataSource<any>([]);
  permissions= [];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  async ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.permissions = await this.permissionService.getPermissoes();
    this.list();
  }

  async list() {
    this.loading = true;
    let page = await this.oculosService.listOculosGlobais(0, 999, this.customFilterContentComponent.buildParamsFilter());
    this.dataSource.data = page.content;
    this.loading = false;
  }

  async search() {
    if (this.formFilter.get('term').value) {
      this.term = this.formFilter.get('term').value;
      this.paginator.pageIndex = 0;
    } else {
      this.term = '';
    }
    await this.list();
  }

  open(bean?) {
    const isMobile = this.breakpointObserver.isMatched('(max-width: 600px)');
    const dialogConfig = {
      data: {bean},
      width: isMobile ? null : '60vw',
      minWidth: isMobile ? '100vw' : '30vw',
      minHeight: isMobile ? '80vh' : '80vh',
      position: isMobile ? {
        bottom: '0',
      } : null

    };

    this.dialog.open(OculosGlobaisModalComponent, dialogConfig);


    this.dialog.afterAllClosed.subscribe(() => {
      this.list();
    });
  }

  protected readonly _ = _;
}
