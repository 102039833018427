import {Component, EventEmitter, ViewChild} from '@angular/core';
import {ListOculosComponent} from '../../components/listagem-oculos/list-oculos.component';
import {Oculos} from '../../shared/models';
import {FormBuilder} from '@angular/forms';
import {StepperComponent} from '../../components/progress-bar/stepper.component';
import {Subscription} from 'rxjs';
import {ImageUploadService} from '../../services/image-upload.service';
import {MarkIrisImageComponent} from '../../components/mark-iris-image/mark-iris-image.component';
import {MedidasService} from '../../services/medidas.service';


@Component({
  selector: 'app-ferramenta medidas',
  templateUrl: './ferramenta-medidas.component.html',
  styleUrls: ['./ferramenta-medidas.component.css']
})
export class FerramentaMedidasComponent {

  oculosSelected: Oculos;
  currentStep: number = 0;
  @ViewChild('applistoculos') listoculos: ListOculosComponent;
  @ViewChild('steps') step: StepperComponent;
  @ViewChild('markiris') markiris: MarkIrisImageComponent;
  private imageUploadSubscription: Subscription;
  image: ImageSnippet;
  imageDisplay: string;
  searchEventEmitter = new EventEmitter<number>();
  media: string;
  loading: boolean = true;

  constructor(private formBuilder: FormBuilder,
              private imageUploadService: ImageUploadService,
              private medidasService: MedidasService) {

    this.imageUploadSubscription = this.imageUploadService.image.subscribe(imageUrl => {
      this.image = imageUrl;
      if (imageUrl != null) {
        this.imageDisplay = imageUrl.src;
        this.currentStep = 2;
      }
    });


  }

  ngOnInit(): void {

  }


  selectedItem(evt) {
    this.oculosSelected = this.listoculos.value;
    if (this.oculosSelected != null) {
      this.currentStep = 1;
    }
  }


  changeSteps(evt) {
    this.currentStep = this.step.selectedStep;

  }

  async sendImage(evt) {
    this.currentStep = 3;
    this.media = this.markiris.media;
    //   let formData = new FormData();
    //
    //   formData.append('image', this.image.file);
    //   formData.append('codigoOculos', this.oculosSelected.codigo);
    //   formData.append('mediaIris', this.markiris.media);

    //   console.log('media iris', this.markiris.media);
    //   formData.append('posicaoY', '0');
    //   formData.append('posicaoX', '0');
    //   const url = environment.apiSiveonDev + '/api/siveon/medidas';
    //   const body = formData;
    //
    //   this.medidasService.sendImageMedidasSiveon(body)
    //     .toPromise()
    //     .then(response => {
    //       console.log('response');
    //
    //       this.currentStep = 3;
    //       // let img = this.dataURLtoFile(this.srcImageDisplay, new Date().toLocaleString());
    //       // this.dawnloadImage(img, '.png');
    //     }).catch(error => {
    //     setTimeout(() => {
    //       window.location.reload();
    //     }, 2000);
    //
    //   });
  }

  load(evt) {
    this.loading = evt;
  }
}

class ImageSnippet {
  constructor(public src: string, public file: File) {
  }
}
