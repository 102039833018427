import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {LayoutProvador, Oculos} from '../shared/models';


class Content {
  content: any;
}

@Injectable({
  providedIn: 'root'
})
export class MedidasService {


  constructor(public http: HttpClient) {
  }

  parameters: any = {};
  url: string = environment.apiSiveonDev;

  sendImageMedidasSiveon(body): Observable<any> {
    return this.http.post(this.url + '/api/siveon/medidas', body,).pipe(map(data => {
      return data;
    }));
  }
  async getSize(body) {
    const response = await this.http.post<any>(this.url + '/api/siveon/medidas/size', body).toPromise();
    return response;
  }

  async move(body) {
    const response = await this.http.post<any>(this.url + '/api/siveon/medidas/move', body).toPromise();
    return response;
  }

  async resize(body) {
    const response = await this.http.post<any>(this.url + '/api/siveon/medidas/resize', body).toPromise();
    return response;
  }



  // async move(body,glassCode,verticalAdjust,horizontalAdjustment,verticalAdjustEyewear,horizontalAdjustmentEyewear) {
  //   let params:any ={};
  //   params.glassCode =glassCode
  //   params.verticalAdjust =verticalAdjust
  //   params.horizontalAdjustment =horizontalAdjustment
  //   params.verticalAdjustEyewear =verticalAdjustEyewear
  //   params.horizontalAdjustmentEyewear =horizontalAdjustmentEyewear
  //   console.log(params);
  //   const response = await this.http.post<any>(environment.apiSiveon + '/resolve/mensure/glasses', body,{params:params}).toPromise();
  //   return response;
  // }

}
