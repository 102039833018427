import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './src/views/home/home.component';
import {DawnloadImageCameraComponent} from './src/views/imageDawnload/dawnload-image-camera.component';
import {AreaMedidasComponent} from './src/views/areaMedidas/area-medidas.component';
import {ProvadorDinamicoComponent} from './src/views/provador-dinamico/provador-dinamico.component';
import {LoginModalComponent} from './src/views/login/login-modal.component';
import {DashboardModalComponent} from './src/views/dashboard/dashboard-modal.component';
import {FerramentaMedidasComponent} from './src/views/ferramenta-medidas/ferramenta-medidas.component';
import {MoveOculosComponent} from './src/components/move-oculos/move-oculos.component';
import {FerramentaCalibracaoComponent} from './src/views/ferramenta-calibracao/ferramenta-calibracao.component';
import {RelatoriosListComponent} from './src/views/relatorios/relatorios-list.component';
import {OculosListComponent} from './src/views/oculos/oculos-list.component';
import {HomeRevendaComponent} from './src/views/home-revenda/home-revenda.component';
import {PainelAdministrativoComponent} from './src/views/painel-administrativo/painel-administrativo.component';
import {PersonalizaProvadorComponent} from './src/views/personalizarprovador/personaliza-provador.component';
import {OculosSolicitacaoListComponent} from './src/views/solicitacoes/oculos-solicitacao-list.component';
import { LeadsListComponent } from 'src/views/leads/leads-list.component';
import {HomeLojistaComponent} from './src/views/home-lojista/home-lojista.component';
import {ProvadoresListComponent} from './src/views/provadores/provadores-list.component';
import {OculosGlobaisListComponent} from './src/views/oculos-globais/oculos-globais-list.component';




const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'home-lojista', component: HomeLojistaComponent},
  {path: 'dashboard', component: DashboardModalComponent},


  {path: 'medida', component: AreaMedidasComponent},
  {path: 'sampler/:domain/:codigo', component: ProvadorDinamicoComponent},
  {path: 'camera', component: DawnloadImageCameraComponent},
  {path: 'login', component: LoginModalComponent},
  {path: 'admin/login', component: LoginModalComponent},
  {path: 'measurement-tool', component: FerramentaMedidasComponent},
  {path: 'measurement-calibrate-tool', component: FerramentaCalibracaoComponent},
  {path: 'iris', component: MoveOculosComponent},

  {path: 'relatorios', component: RelatoriosListComponent},
  {path: 'oculos', component: OculosListComponent},
  {path: 'home-revenda', component: HomeRevendaComponent},
  {path: 'painel-administrativo/:tenantid', component: PainelAdministrativoComponent},
  {path: 'personalizar-provador/admin/:tenantid', component: PersonalizaProvadorComponent},
  {path: 'personalizar-provador/:domain', component: PersonalizaProvadorComponent},
  {path: 'solicitacoes', component: OculosSolicitacaoListComponent},
  {path: 'leads', component: LeadsListComponent},
  {path: 'listagem-templates-provador', component: ProvadoresListComponent},
  {path: 'oculos-store', component: OculosGlobaisListComponent},



  {
    path: 'dashboard', component: DashboardModalComponent,

  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
