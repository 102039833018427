import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'dragdrop-upload',
  templateUrl: './dragdrop-upload.component.html',
  styleUrls: ['./dragdrop-upload.component.css']
})
export class DragdropUploadComponent {


  arquivoSelecionado: File;

  @Input()
  image: string = null;

  @Input()
  showDownladImage:boolean = true;

  @Input()
  showClearImage:boolean = true;

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  @Output() arquivoSelecionadoChange: EventEmitter<File> = new EventEmitter<File>();


  onFileSelected(event: any) {
    this.arquivoSelecionado = event.target.files[0];
    if (this.arquivoSelecionado) {
      this.loadImage(this.arquivoSelecionado);

    }
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    const file = event.dataTransfer?.files[0];
    this.arquivoSelecionado = file;

    if (file) {
      this.loadImage(file);
    }
  }

  allowDrop(event: DragEvent): void {
    event.preventDefault();
  }

  loadImage(file: File): void {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.image = e.target?.result as string;
      this.arquivoSelecionadoChange.emit(this.arquivoSelecionado);
    };
    reader.readAsDataURL(file);
  }

  clearImage(): void {
    this.image = null;
  }

  downloadImage() {
    // Verifica se há uma imagem
    if (this.image) {
      // Converte a imagem para um blob
      fetch(this.image)
        .then(response => response.blob())
        .then(blob => {
          // Cria um link temporário
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'image_download'; // Nome do arquivo a ser baixado

          // Adiciona o link ao documento
          document.body.appendChild(link);

          // Simula o clique no link para iniciar o download
          link.click();

          // Remove o link do documento
          document.body.removeChild(link);
        });
    }
  }

}
