import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PermissaoService} from '../../services/permissao.service';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {SnackMessageService} from '../../components/httpresponseinterceptor/snack-message-service';

@Component({
  selector: 'tenant-modal',
  templateUrl: './permissao-modal.component.html',

})
export class PermissaoModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) private data,
              private formBuilder: FormBuilder,
              private permissaoService: PermissaoService,
              private snackService: SnackMessageService
  ) {

  }


  loading: boolean = true;
  displayedColumns: string[] = ['select', 'permission'];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);


  formUser = this.formBuilder.group({
    'id': [''],
    'username': [''],
    'password': [''],
  });


  async ngOnInit() {
    await this.list();
    this.loading = false;
  }

  async save() {
    await this.close();
  }

  async list() {
    this.loading = true;
    let permissions = await this.permissaoService.getAllPermissionsByUser(this.data.bean.id);
    this.dataSource.data = permissions.permissoes;
    this.loading = false;
  }

  async addPermissao(row) {
    this.loading = true;
    await this.permissaoService.addPermissao(this.data.bean.id, row.acaoEnum).then(res => {
      this.snackService.showSuccessMessage('Permissão adicionada com sucesso!');
    }).catch(e => {
      this.snackService.showErrorMessage('Erro ao adicionar permissão!');
    });
    await this.list();
  }

  async removePermissao(row) {
    this.loading = true;
    await this.permissaoService.removePermissao(this.data.bean.id, row.acaoEnum).then(res => {
      this.snackService.showSuccessMessage('Permissão removida com sucesso!');
    }).catch(e => {
      this.snackService.showErrorMessage('Erro ao remover permissão!');
    });
    await this.list();
  }

  async close() {
    await this.dialogRef.close();
  }


  protected readonly Object = Object;
}
