import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageUploadService {
  private imageSubject = new BehaviorSubject<ImageSnippet>(null);
  image = this.imageSubject.asObservable();

  constructor() { }

  setImage(imageUrl: ImageSnippet) {
    this.imageSubject.next(imageUrl);
  }
  removeImage(){
    this.imageSubject.next(null);
  }
}
class ImageSnippet {
  constructor(public src: string, public file: File) {
  }
}
