import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ProvadorService} from '../../services/provador.service';
import {LayoutProvador} from '../../shared/models';
import {ProvadorBuilderService} from '../../services/provador-builder.service';
import {Provador} from '../../services/provador';
import * as _ from 'lodash';

// import {promise} from 'protractor';


@Component({
  selector: 'app-provador',
  templateUrl: './provador-dinamico.component.html',
  styleUrls: ['./provador-dinamico.component.css']
})
export class ProvadorDinamicoComponent implements OnInit {

  isloading = true;
  domain: string;
  codigo: string;
  builderProvador: Provador = null;
  linkRedirecionamento = '';


  constructor(private routePath: ActivatedRoute,
              private siveonService: ProvadorService,
              private builderProvadorService: ProvadorBuilderService) {

  }

  async ngOnInit() {
    this.codigo = this.routePath.snapshot.paramMap.get('codigo');
    this.domain = this.routePath.snapshot.paramMap.get('domain');

    const [layout, linkRedirecionamento] = await Promise.all([
      this.siveonService.getLayoutProvador(this.domain),
      this.siveonService.getLinkRedicionamento(this.domain, this.codigo)
    ]);

    this.builderLayout(layout, linkRedirecionamento);
    this.isloading = false;
  }


  builderLayout(layout: any, linkRedirecionamento: any) {
    this.builderProvador = this.builderProvadorService
      .withLinkRedirecionamento(linkRedirecionamento)
      .withCodigoOculos(this.codigo)
      .withLogo(_.get(layout, 'logo'))
      .withCorDeFundo(_.get(layout, 'corDeFundo'))
      .withCorTituloBemVindo(_.get(layout, 'corTituloBemVindo'))
      .withCorCardExterno(_.get(layout, 'corCardExterno'))
      .withCorCardInterno(_.get(layout, 'corCardInterno'))
      .withCorSubtitulo(_.get(layout, 'corSubtitulo'))
      .withCorInstrucoes(_.get(layout, 'corInstrucoes'))
      .withCorIconesInstrucoes(_.get(layout, 'corIconesInstrucoes'))
      .withCorFundoBotaoAdicionarImagem(_.get(layout, 'corFundoBotaoAdicionarImagem'))
      .withCorTextoAdicionarImagem(_.get(layout, 'corTextoAdicionarImagem'))
      .withCorIconeAdicionarImagem(_.get(layout, 'corIconeAdicionarImagem'))
      .withCorTextoRetornarProduto(_.get(layout, 'corTextoRetornarProduto'))
      .withCorIconeRetornarProduto(_.get(layout, 'corIconeRetornarProduto'))
      .withCorFundoBotaoEnviarImagem(_.get(layout, 'corFundoBotaoEnviarImagem'))
      .withCorTextoEnviarImagem(_.get(layout, 'corTextoEnviarImagem'))
      .withCorIconeEnviarImagem(_.get(layout, 'corIconeEnviarImagem'))
      .withCorFundoBotaoNovaImagem(_.get(layout, 'corFundoBotaoNovaImagem'))
      .withCorTextoNovaImagem(_.get(layout, 'corTextoNovaImagem'))
      .withcorIconeNovaImagem(_.get(layout, 'corIconeNovaImagem'))
      .withCorFundoBotaoContinuarCompra(_.get(layout, 'corFundoBotaoContinuarCompra'))
      .withCorTextoContinuarCompra(_.get(layout, 'corTextoContinuarCompra'))
      .withcorIconeContinuarCompra(_.get(layout, 'corIconeContinuarCompra'))
      .build();


  }


}
