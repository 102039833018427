import {Component, Inject, OnInit} from '@angular/core';
import {OculosService} from '../../services/oculos.service';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {InclusaoOculosService} from '../../services/inclusao-oculos.service';
import {SnackMessageService} from '../../components/httpresponseinterceptor/snack-message-service';
import {PermissaoService} from '../../services/permissao.service';
import * as _ from 'lodash';
import {AuthService} from '../../services/auth.service';

enum StatusEnum {
  SOLICITADO = 'Solicitado a inclusão do óculos no sistema',
  DESENVOLVIMENTO = 'Óculos em produção',
  CORRECAO = 'Óculos em correção',
  FINALIZADO = 'Óculos disponível para uso'
}

enum StatusEnumSolicitacao {
  SOLICITADO = 'Solicitado a inclusão do óculos no sistema'

}

enum TipoOculosEnum {
  MARCA_PROPRIA = 'Óculos de marca propria',
  MARCA_GLOBAL = 'Óculos de marca global'
}

@Component({
  selector: 'oculos-modal',
  templateUrl: './oculos-modal.component.html',
  styleUrls: ['./oculos-list.component.css']

})
export class OculosModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<any>,
              private authService: AuthService,
              @Inject(MAT_DIALOG_DATA) private data,
              private formBuilder: FormBuilder,
              private oculosService: OculosService,
              private inclusaoOculosService: InclusaoOculosService,
              private snackService: SnackMessageService,
              private permissionService: PermissaoService
  ) {

  }


  loading: boolean = true;
  saving: boolean = false;
  loadingGlobalOculos: boolean = false;
  image: string = null;
  isAdmin: boolean = false;
  tenantId: number = null;
  arquivoSelecionado: File = null;
  statusEnumValues = [];
  statusEnumSolicitacaoValues = [];
  tiposOculosEnumValues = [];
  oculosCadastrados: any = [];
  oculosSelecionadoClonagem: any;
  permissions = [];

  formOculos = this.formBuilder.group({
    'id': [''],
    'linkProduto': [''],
    'modelo': [''],
    'referenciaArmacao': [''],
    'codigoSiveon': [''],
    'linkImage': [''],
    'larguraArmacao': new FormControl('', [
      Validators.pattern('^[0-9]*$') // Allows only numbers
    ]),
    'alturaArmacao': new FormControl('', [
      Validators.pattern('^[0-9]*$') // Allows only numbers
    ]),
    'situacaoProvador': ['SOLICITADO'],
    'situacaoAreaMedidas': ['SOLICITADO'],
    'tipoOculos': [{value: 'MARCA_PROPRIA', disabled: true}],
    'image': [],
    'possuiImagem': false

  });

  formlinkProvador = this.formBuilder.group({
    'linkProvador': [''],
  });

  async ngOnInit() {
    this.permissions = await this.permissionService.getPermissoes();
    const context = await this.authService.context();
    const perfil = _.get(context, 'perfil');
    this.tenantId = _.get(context, 'tenantId');

    if (perfil === 'ADMINISTRADOR_SIVEON') {
      this.isAdmin = true;
      this.initAdmin();
      return;
    }

    this.formOculos.markAsUntouched();
    if (this.data.bean != null) {
      this.formOculos.patchValue(this.data.bean);
      if (this.data.bean.codigoSiveon != null) {
        // tslint:disable-next-line:max-line-length
        this.formlinkProvador.get('linkProvador').setValue(`https://siveon.com.br/sampler/${this.data.bean.tenant.domain}/${this.data.bean.codigoSiveon}`);
      }
      const image: any = await this.inclusaoOculosService.getImage(this.data.bean.id);

      if (image.content.length !== 0) {
        this.image = 'data:image/png;base64,' + image.content;
      }
    }
    this.loadEnuns();
    this.loading = false;
  }

  loadEnuns() {
    this.statusEnumValues = Object.keys(StatusEnum).map(key => ({
      value: key,
      description: StatusEnum[key]
    }));
    this.tiposOculosEnumValues = Object.keys(TipoOculosEnum).map(key => ({value: key, description: TipoOculosEnum[key]}));
  }

  async initAdmin() {
    this.formOculos.markAsUntouched();
    if (this.data.bean != null) {
      this.formOculos.patchValue(this.data.bean);

      const image: any = await this.inclusaoOculosService.getImageOculosByCodigo(this.data.bean.codigoSiveon);

      if (image.content.length !== 0) {
        this.image = 'data:image/png;base64,' + image.content;
      }
    }
    this.loadEnuns();
    this.loading = false;
  }


  async cloneOculos() {
    this.loadingGlobalOculos = true;
    if (!_.isEmpty(this.oculosCadastrados)) {
      this.loadingGlobalOculos = false;
      return;
    }
    this.oculosService.getAll().then(result => {
      this.oculosCadastrados = result.content;
      this.loadingGlobalOculos = false;
    });

  }

  preencherFormOculos() {
    let oculos = this.oculosSelecionadoClonagem;
    this.formOculos.patchValue({
      'id': null,
      'linkProduto': null,
      'modelo': oculos.modelo,
      'codigoSiveon': oculos.codigoSiveon,
      'linkImage': oculos.linkImage,
      'larguraArmacao': oculos.larguraArmacao,
      'alturaArmacao': oculos.alturaArmacao,
      'situacaoProvador': oculos.situacaoProvador,
      'situacaoAreaMedidas': oculos.situacaoAreaMedidas,
      'tipoOculos': oculos.tipoOculos,
      'image': oculos.image,
      'possuiImagem': oculos.possuiImagem,
      'referenciaArmacao': oculos.referenciaArmacao,
    });
    this.getImageOculosByCodigo(oculos.codigoSiveon);
    this.formOculos.markAllAsTouched();
  }

  async save() {
    this.saving = true;
    if (this.arquivoSelecionado != null) {
      this.formOculos.get('possuiImagem').setValue(true);
    } else {
      this.formOculos.get('possuiImagem').setValue(false);
    }

    if (this.isAdmin) {
      await this.oculosService.saveAdminValues(this.formOculos.value, this.tenantId).then(data =>
        this.formOculos.patchValue(data));
    } else {
      await this.oculosService.save(this.formOculos.value).then(data => this.formOculos.patchValue(data));
    }
    if (this.arquivoSelecionado != null) {
      this.oculosService.saveImagem(this.resolveParameters());

    }
    this.saving = false;
    await this.close();
  }

  resolveParameters() {
    const formData = new FormData();
    formData.append('image', this.arquivoSelecionado);
    formData.append('oculosId', this.formOculos.get('id').value);
    return formData;
  }

  onArquivoSelecionadoChange(arquivo: File) {
    this.arquivoSelecionado = arquivo;
  }

  async getImageOculosByCodigo(codigo: string) {
    let image: any = await this.inclusaoOculosService.getImageOculosByCodigo(codigo);

    if (image.content.length != 0) {
      this.image = 'data:image/png;base64,' + image.content;
    }
  }

  close() {
    this.dialogRef.close();
  }

  copyLink() {
    let value: string = this.formlinkProvador.get('linkProvador').value;
    window.navigator['clipboard'].writeText(value).then(() => {

    });
  }

  protected readonly _ = _;
}
