import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {OculosService} from '../../services/oculos.service';
import {FormBuilder} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {Oculos, Pageable} from '../../shared/models';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {BreakpointObserver} from '@angular/cdk/layout';
import {DragdropUploadComponent} from '../../components/dragdropupload/dragdrop-upload.component';
import {Router} from '@angular/router';
import {PermissaoService} from '../../services/permissao.service';
import * as _ from 'lodash';
import {CustomFilterContentComponent} from '../../components/filter/custom-filter-content.component';
import {OculosModalComponent} from '../oculos/oculos-modal.component';

enum StatusEnum {
  SOLICITADO = 'Solicitado a inclusão do óculos no sistema',
  DESENVOLVIMENTO = 'Óculos em produção',
  CORRECAO = 'Óculos em correção',
  FINALIZADO = 'Óculos disponível para uso'
}

@Component({
  selector: 'oculos-solicitacao-list',
  templateUrl: './oculos-solicitacao-list.component.html',
  styleUrls: ['./oculos-solicitacao-list.component.css']
})
export class OculosSolicitacaoListComponent implements OnInit {
  formFilter = this.formBuilder.group({
    'term': [''],
  });
  displayedColumns = ['nome', 'codigo', 'tenantSolicitacao', 'usuarioSolicitacao', 'edit'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  listOculos: any[] = [];
  page: Pageable<Oculos>;
  term: string = '';
  loading: boolean = true;
  @Input()
  isAdmin: boolean = false;
  quantidadeDisponivel = null;
  permissions = [];

  @Input()
  tenantId: number = null;

  @ViewChild(DragdropUploadComponent) teste: DragdropUploadComponent;
  @ViewChild(CustomFilterContentComponent) customFilterContentComponent: CustomFilterContentComponent;

  constructor(private authService: AuthService,
              private formBuilder: FormBuilder,
              private changeDetectorRef: ChangeDetectorRef,
              private oculosService: OculosService,
              private dialog: MatDialog,
              private breakpointObserver: BreakpointObserver,
              private router: Router,
              private permissionService: PermissaoService
  ) {
    this.searchEventEmitter.subscribe(async () => {
      this.list();
    });
  }

  filtroOculos: any[] = [
    {
      descricao: 'Nome',
      value: 'modelo',
      keys: ['modelo'],
      type: 'STRING'
    },

    {
      descricao: 'Codigo',
      value: 'codigoSiveon',
      keys: ['codigoSiveon'],
      type: 'STRING'
    }, {
      descricao: 'Usuario',
      value: 'usuario.username',
      keys: ['usuario.username'],
      type: 'STRING'
    }, {
      descricao: 'Tenant',
      value: 'tenant.nome',
      keys: ['tenant.nome'],
      type: 'STRING'
    },


  ];

  order: any = {};


  async ngAfterViewInit() {
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 10;
    this.paginator.page.subscribe(() => this.list());

    await this.list();
    this.changeDetectorRef.detectChanges();


  }

  async list() {
    this.loading = true;
    const pageIndex = this.paginator.pageIndex;
    const pageSize = this.paginator.pageSize;
    this.page = await this.oculosService.listSolicitacao(pageIndex, pageSize, this.customFilterContentComponent.buildParamsFilter());
    this.dataSource.data = this.page.content;
    this.loading = false;
  }

  async search() {

    if (this.formFilter.get('term').value) {
      this.term = this.formFilter.get('term').value;
      this.paginator.pageIndex = 0;
    } else {
      this.term = '';
    }
    await this.list();
  }

  buildOptions(): {} {
    let order = {};
    if (this.order.value) {
      order[this.order.value] = this.order.order;
    }
    return {
      where: this.customFilterContentComponent.buildParamsFilter(),
      order: order,
    };
  }

  open(bean?) {
    const isMobile = this.breakpointObserver.isMatched('(max-width: 600px)');
    const context = {'admin': true, 'tenantId': bean.tenant.id};
    const dialogConfig = {
      width: isMobile ? '100%' : '800px',
      height: isMobile ? '100%' : '800px',
      data: {bean, context},
    };
    this.dialog.open(OculosModalComponent, dialogConfig);


    this.dialog.afterAllClosed.subscribe(() => {
      this.list();
    });
  }

  async openLayoutProvador(bean?) {
    if (_.isEmpty(this.tenantId)) {
      let contextPromise: any = await this.authService.context();
      const domain = contextPromise.domain;
      this.router.navigate(['personalizar-provador/', domain]);

    } else {
      this.router.navigate(['personalizar-provador/admin', this.tenantId]);
    }


  }


  searchEventEmitter: EventEmitter<any> = new EventEmitter();

  customOrder(newOrder) {
    if (this.order && this.order.value && this.order.value === newOrder) {
      this.order.order = (this.order.order === 'ASC') ? 'DESC' : 'ASC';
    } else {
      this.order = {
        value: newOrder,
        order: 'ASC'
      };
    }
    this.searchEventEmitter.emit();
  }


  async ngOnInit() {
    let quantidadeDisponivel = await this.oculosService.getQuantidadeDisponivel();
    this.quantidadeDisponivel = quantidadeDisponivel.quantidade;
  }


  protected readonly _ = _;
}
