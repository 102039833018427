import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TenantService} from '../../services/tenant.service';
import {UsuarioService} from '../../services/usuario.service';

@Component({
  selector: 'tenant-modal',
  templateUrl: './usuario-modal.component.html',

})
export class UsuarioModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) private data,
              private formBuilder: FormBuilder,
              private usuarioService: UsuarioService
  ) {

  }


  loading: boolean = true;


  formUser = this.formBuilder.group({
    'id': [''],
    'username': [''],
    'password': [''],
  });


  async ngOnInit() {
    if (this.data.bean != null) {
      this.formUser.markAsUntouched();
      this.formUser.patchValue(this.data.bean);

    }

    this.loading = false;
  }

  async save() {
    await this.usuarioService.save(this.formUser.value,this.data.tenantId);
    this.close();
  }

  async close() {
    await this.dialogRef.close();
  }


}
