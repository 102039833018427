import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {FormBuilder} from '@angular/forms';
import {SelecionaValoresProvadorModalComponent} from './seleciona-valores-provador-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';
import {ProvadorService} from '../../services/provador.service';
import {StepperComponent} from '../../components/progress-bar/stepper.component';

import {SnackMessageService} from '../../components/httpresponseinterceptor/snack-message-service';

@Component({
  selector: 'personaliza-list',
  templateUrl: './personaliza-provador.component.html',
  styleUrls: ['./personaliza-provador.component.css']
})
export class PersonalizaProvadorComponent implements OnInit {


  loading: boolean = true;
  tenantId: any;
  domain: any;
  currentStep: number = 0;
  passos = ['Step 1', 'Step 2', 'Step 3', 'Step 4']
  @ViewChild('steps') step: StepperComponent;

  constructor(private authService: AuthService,
              private formBuilder: FormBuilder,
              private dialog: MatDialog,
              private routeActivate: ActivatedRoute,
              private provadorService: ProvadorService,
              private snackService: SnackMessageService
  ) {

  }


  async ngAfterViewInit() {
    this.loading = false;
  }

  layoutProvadorByTenant
  async ngOnInit() {
    this.tenantId = this.routeActivate.snapshot.paramMap.get('tenantid');
    this.domain = this.routeActivate.snapshot.paramMap.get('domain');
    if(this.tenantId != null){
      this.layoutProvadorByTenant = await this.provadorService.getLayoutProvadorByTenant(this.tenantId);
    }else {
      this.layoutProvadorByTenant =  await this.provadorService.getLayoutProvador(this.domain);
    }

    if (this.layoutProvadorByTenant != null) {
      this.formLogo.patchValue(this.layoutProvadorByTenant);
      this.formPrimeiraTela.patchValue(this.layoutProvadorByTenant);
      this.formSegundaTela.patchValue(this.layoutProvadorByTenant);
      this.formTerceiraTela.patchValue(this.layoutProvadorByTenant);
      this.formLogo.markAsUntouched();
      this.formPrimeiraTela.markAsUntouched();
      this.formSegundaTela.markAsUntouched();
      this.formTerceiraTela.markAsUntouched();

    }
    this.loading = false;

  }

  changeSteps(evt) {
    this.currentStep = this.step.selectedStep;

  }

  formLogo = this.formBuilder.group({
    'id':[''],
    'marginLeftLogo': ['10px'],
    'heightLogo': ['85px'],
    'marginTopLogo': ['10px'],
    'widthLogo': ['85px'],
    'logo': ['../../assets/logoazul.png'],

  });

  formPrimeiraTela = this.formBuilder.group({
    'fundoPagina': ['#ffffff'],
    'backgroundColorWelcome': ['#ffffff'],
    'boxShadowWelcome': ['none'],
    'fontColorWelcome': ['#010607'],
    'backgroundColorWelcomeSecondCard': ['#ffffff'],
    'text1': ['#11224E'],
    'backgroundColorButtonAddImage': ['#F88125'],
    'fontColorButtonAddImage': ['#ffffff'],
    'iconColorButtonAddImage': ['#ffffff'],
    'iconColorReturn': ['#11224E'],
    'fontColorReturn': ['#11224E'],
  });

  formSegundaTela = this.formBuilder.group({
    'backgroundColorSecondCard': ['#ffffff'],
    'fontColorTitleSecond': ['#ffffff'],
    'iconsInstrucaoColor': ['#ffffff'],
    'backgroundColorbuttonSend': ['#010607'],
    'fontColorButtonSend': ['#ffffff'],
    'iconColorButtonSend': ['#11224E'],
  });

  formTerceiraTela = this.formBuilder.group({
    'backgroundColorThirdCard': ['#ffffff'],
    'backgroundColorReturnPhoto': ['#ffffff'],
    'fontColorReturnPhoto': ['#ffffff'],
    'colorIconReturnPhoto': ['#010607'],
    'backgroundColorGoBuy': ['#ffffff'],
    'fontColorGoBuy': ['#11224E'],
    'colorIconGoBuy': ['#11224E'],
  });



  abrirOpcaoEstilo(type: string) {
    const dialogRef = this.dialog.open(SelecionaValoresProvadorModalComponent, {
      data: this.resolveTypeData(type),
      width: '400px',
      height: '800px',
      position: {right: '0'}
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  save(){
    if(this.tenantId != null) {
      this.provadorService.saveLayoutProvadorByTenant(this.tenantId, this.juntarForms()).then(res => {
        this.snackService.showSuccessMessage("Layout do provador salvo com sucesso")
      }).catch(error => {
        this.snackService.showErrorMessage("Erro ao salvar o layout do provador!")
      })
    }else {
      this.provadorService.saveLayoutProvador(this.juntarForms()).then(res => {
        this.snackService.showSuccessMessage("Layout do provador salvo com sucesso")
      }).catch(error => {
        this.snackService.showErrorMessage("Erro ao salvar o layout do provador!")
      })
    }
  }

  juntarForms(){
    const combinedFormData = {
      ...this.formLogo.value,
      ...this.formPrimeiraTela.value,
      ...this.formSegundaTela.value,
      ...this.formTerceiraTela.value,
    };

    return combinedFormData;
  }

  resolveTypeData(type: string) {
    switch (type) {
      case 'LOGO':
        return {
          form: this.formLogo,
          title: 'Ajuste Logo',
          type: 'LOGO',
        };
      case 'PRIMEIRA_TELA':
        return {
          form: this.formPrimeiraTela,
          title: 'Ajuste Primeira Tela',
          type: 'PRIMEIRA_TELA',
        };
      case 'SEGUNDA_TELA':
        return {
          form: this.formSegundaTela,
          title: 'Ajuste Segunda Tela',
          type: 'SEGUNDA_TELA',
        };
      case 'TERCEIRA_TELA':
        return {
          form: this.formTerceiraTela,
          title: 'Ajuste Terceira Tela',
          type: 'TERCEIRA_TELA',
        };
    }
  }



}
