import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {OculosService} from '../../services/oculos.service';
import {Oculos} from '../../shared/models';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../environments/environment';
import {ImageUploadService} from '../../services/image-upload.service';
import {MatDialog} from '@angular/material/dialog';
import {HttpResponseInterceptorComponent} from '../httpresponseinterceptor/http-response-interceptor.component';
import {MedidasService} from '../../services/medidas.service';


@Component({
  selector: 'app-move-oculos',
  templateUrl: 'move-oculos.component.html',
  styleUrls: ['move-oculos.component.css']
})
export class MoveOculosComponent implements OnInit {


  @Input()
  image: ImageSnippet;

  @Input()
  oculosSelected: Oculos;

  @Input()
  media: any = '30';

  @Input()
  codigo: any = '1f46f3438173403e8c01efec34bbc14e';

  @Input()
  imageDisplay: any = './assets/mask2.png';

  @Input()
  glasseResized: any;

  posicaoVerticalOculos: number = 0.0;
  posicaoHorizontalOculos: number = 0;
  largura: string = '250';
  altura: string = '250';
  bean: Oculos;
  ruler: any;
  isReguaVertical: boolean = false;
  isReguaHorizontal: boolean = false;

  @Input()
  calibrateOculos: boolean = false;

  @ViewChild('imageOculosElement') imageOculosDom: ElementRef;
  loading = true;

  constructor(private medidasService: MedidasService,
              private oculosService: OculosService,
              private formBuilder: FormBuilder,
              private elementRef: ElementRef,
              private changeDetectorRef: ChangeDetectorRef
  ) {
  }


  formFilter = this.formBuilder.group({
    fatorConversaoAltura: new FormControl('', [Validators.required, this.twoDecimalPlaces]),
    fatorConversaoLargura: new FormControl('', [Validators.required, this.twoDecimalPlaces]),
    zoom: new FormControl('1.00', [Validators.required, this.twoDecimalPlaces])
  });

  zoom() {
    this.elementRef.nativeElement.style.zoom = this.formFilter.get('zoom').value;
  }


  async ngOnInit() {
    let formData = new FormData();
    formData.append('codigoOculos', await this.codigo);
    formData.append('mediaIris', await String(this.media));

    let size = await this.medidasService.getSize(formData);
    this.largura = size.largura;
    this.altura = size.altura;
    if (this.calibrateOculos) {
      await this.loadFields();
    }
    await this.getImage();
    await this.getRuler();
    this.loading = false;
  }

  async loadFields() {
    this.bean = await this.oculosService.get(this.oculosSelected.id);
    this.formFilter.get('fatorConversaoAltura').setValue(this.bean.fatorConversaoAltura);
    this.formFilter.get('fatorConversaoLargura').setValue(this.bean.fatorConversaoLargura);
    this.formFilter.markAllAsTouched();
  }

  async getImage() {
    this.glasseResized = 'data:image/png;base64,'
      + await this.oculosService.getImage(this.codigo, this.altura, this.largura);
    this.changeDetectorRef.detectChanges();

  }

  async getRuler() {
    this.ruler = 'data:image/png;base64,'
      + await this.oculosService.getImage('ruler', this.altura, this.largura);
    this.changeDetectorRef.detectChanges();

  }


  async sendImage() {

    let formData = new FormData();
    formData.append('image', await this.image.file);
    formData.append('codigoOculos', await this.codigo);
    formData.append('mediaIris', await String(this.media));
    formData.append('posicaoY', await String(this.posicaoVerticalOculos));
    formData.append('posicaoX', await String(this.posicaoHorizontalOculos));
    formData.append('alturaArmacao', await String(this.altura));
    formData.append('larguraArmacao', await String(this.largura));


    const body = formData;

    await this.medidasService.move(body)
      .then(response => {

        this.imageDisplay = 'data:image/png;base64,' + response;
        // let img = this.dataURLtoFile(this.srcImageDisplay, new Date().toLocaleString());
        // this.dawnloadImage(img, '.png');
      }).catch(error => {

      });
  }

  twoDecimalPlaces(control: AbstractControl): { [key: string]: boolean } | null {
    const value = control.value;
    if (value === null || value === '') {
      return null;
    }
    const decimalRegex = /^\d{0,2}(\.\d{1,2})?$/;
    const valid = decimalRegex.test(value);
    return valid ? null : {'twoDecimalPlaces': true};
  }

  async updatedFatorOculos() {

    let fatorConversaoAltura = this.formFilter.value.fatorConversaoAltura;
    let fatorConversaoLargura = this.formFilter.value.fatorConversaoLargura;
    let oculos = await this.oculosService.updateFatorConversaoOculos(this.bean.id, fatorConversaoAltura, fatorConversaoLargura);

    this.bean = oculos;
    await this.getRuler();
    await this.ngOnInit();
  }

  showReguaHorizontal() {
    this.isReguaHorizontal = true;
    this.isReguaVertical = false;
  }

  showReguaVertical() {
    this.isReguaVertical = true;
    this.isReguaHorizontal = false;
  }


}

class ImageSnippet {
  constructor(public src: string, public file: File) {
  }
}
