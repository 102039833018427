import {Component, HostListener, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import * as _ from 'lodash';
import {PermissaoService} from '../../services/permissao.service';
import {ActivatedRoute, Router} from '@angular/router';


@Component({
  selector: 'app-menu-lateral',
  templateUrl: './menu-lateral.component.html',
  styleUrls: ['./menu-lateral.component.css']
})
export class MenuLateralComponent implements OnInit {

  panelOpenState = false;
  title = 'SIVEON';
  isPublicItem: boolean;
  permissions = [];
  isAdmin = false;
  isExpanded = false;

  constructor(private authService: AuthService,
              private permissionService: PermissaoService,
              private route: Router,
              private routeActivate: ActivatedRoute,
  ) {
  }

  async ngOnInit() {
    const context = await this.authService.context();
    const perfil = _.get(context, 'perfil');
    if (perfil === 'ADMINISTRADOR_SIVEON') {
      this.isAdmin = true;
      return;
    }
    this.permissions = await this.permissionService.getPermissoes();
  }

  async logout() {
    await this.authService.logout();
  }

  async openFerrmantaMeida() {
    await this.route.navigate(['/measurement-tool']);
  }

  async openCalibrarMedida() {
    await this.route.navigate(['/measurement-calibrate-tool']);
  }

  async openRelatorios() {
    await this.route.navigate(['/relatorios']);
  }

  async openStoreOculos() {
    await this.route.navigate(['/oculos-store']);
  }

  async openListagemConfiguracaoOculos() {
    await this.route.navigate(['/oculos']);
  }

  async openDashboard() {
    await this.route.navigate(['/dashboard']);
  }

  async openHome() {
    await this.route.navigate(['/home-lojista']);
  }

  async openProvadores() {
    await this.route.navigate(['/listagem-templates-provador']);
  }


  async openSolicitacoes() {
    await this.route.navigate(['/solicitacoes']);
  }

  async openLeads() {
    await this.route.navigate(['/leads']);
  }


}
