import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-error-popup',
  templateUrl: './error-popup.component.html',
  styleUrls: ['./error-popup.component.css']
})
export class HttpResponseInterceptorComponent implements OnInit {


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
             ) {
  }


  get message(): string {
    return this.data?.message || '';
  }

  ngOnInit(): void {

  }
}
