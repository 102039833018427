import {Component, HostListener, OnInit} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {AuthService} from './src/services/auth.service';
import {PermissaoService} from './src/services/permissao.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  panelOpenState = false;
  title = 'SIVEON';
  isPublicItem: boolean;
  permissions = [];

  constructor(private authService: AuthService,
              private permissionService: PermissaoService,
              private route: Router,
              private routeActivate: ActivatedRoute,
  ) {
  }

  checkRoutesPublics() {
    return (window.location.pathname.includes('sampler') || window.location.pathname === '/' || window.location.pathname === '/login' || window.location.pathname === '/admin/login');
  }

  isAdmin: boolean = false;

  async ngOnInit() {

    if (!this.checkRoutesPublics()) {
      this.permissions = await this.permissionService.getPermissoes();
      await this.checkIsAdmin();
    }
  }

  isExpanded = false;

  expandMenu() {
    this.isExpanded = true;
  }

  async checkIsAdmin() {
    let context = await this.authService.context();
    let perfil = _.get(context, 'perfil');
    if (perfil === 'ADMINISTRADOR_SIVEON') {
      this.isAdmin = true;
      return;
    }
    this.isAdmin = false;
  }

  collapseMenu() {
    this.isExpanded = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    // Recolha o menu se a largura da janela for menor que um determinado valor
    if (event.target.innerWidth < 768) {
      this.isExpanded = false;
    }
  }

  async logout() {
    await this.authService.logout();
  }

  async openFerrmantaMeida() {
    await this.route.navigate(['/measurement-tool']);
  }

  async openCalibrarMedida() {
    await this.route.navigate(['/measurement-calibrate-tool']);
  }

  async openRelatorios() {
    await this.route.navigate(['/relatorios']);
  }

  async openListagemConfiguracaoOculos() {
    await this.route.navigate(['/oculos']);
  }

  async openDashboard() {
    await this.route.navigate(['/dashboard']);
  }
  async openTenants() {
    await this.route.navigate(['/home-revenda']);
  }


  async openSolicitacoes() {
    await this.route.navigate(['/solicitacoes']);
  }

  async openLeads() {
    await this.route.navigate(['/leads']);
  }



  protected readonly _ = _;
}
