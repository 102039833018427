import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';
import {environment} from '../../environments/environment';
import {ProvadorService} from '../../services/provador.service';

class ImageSnippet {
  constructor(public src: string, public file: File) {
  }
}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'dialog-overview-example-dialog.html',
  styleUrls: ['dialog-overview-example-dialog.css'],
})
export class DialogOverviewExampleDialog implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private service: ProvadorService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  olhoEsquerdoBordaEsquerda: boolean = false;
  olhoEsquerdoBordaDireita: boolean = false;
  olhoDireitoBordaEsquerda: boolean = false;
  olhoDireitoBordaDireita: boolean = false;
  panelOpenState = false;
  imageFile: File = null;
  imageDisplay: any;
  mediaIris: any;
  tamanhoHorizontalOculos: number = 200;
  tamanhoVerticalOculos: number = 50;
  posicaoVerticalOculos: number = 0.0;
  posicaoHorizontalOculos: number = 0;

  formFilter = this.formBuilder.group({
    olhoEsquerdoBordaEsquerda: new FormControl('',),
    olhoEsquerdoBordaDireita: new FormControl('',),
    olhoDireitoBordaEsquerda: new FormControl('',),
    olhoDireitoBordaDireita: new FormControl('',),

  });

  getDistancePixels(evt) {
    if (this.olhoEsquerdoBordaDireita) {
      this.formFilter.value.olhoEsquerdoBordaDireita = evt.clientX;
      this.formFilter.controls['olhoEsquerdoBordaDireita'].setValue(evt.clientX);
      this.olhoEsquerdoBordaDireita = false;
      this.cdRef.detectChanges();

    }
    if (this.olhoEsquerdoBordaEsquerda) {
      this.formFilter.value.olhoEsquerdoBordaEsquerda = evt.clientX;
      this.formFilter.value.olhoEsquerdoBordaEsquerda = evt.clientX;
      this.formFilter.controls['olhoEsquerdoBordaEsquerda'].setValue(evt.clientX);
      this.olhoEsquerdoBordaEsquerda = false;
      this.cdRef.detectChanges();
    }

    if (this.olhoDireitoBordaEsquerda) {
      this.formFilter.value.olhoDireitoBordaEsquerda = evt.clientX;
      this.formFilter.value.olhoDireitoBordaEsquerda = evt.clientX;
      this.formFilter.controls['olhoDireitoBordaEsquerda'].setValue(evt.clientX);
      this.olhoDireitoBordaEsquerda = false;
      this.cdRef.detectChanges();
    }
    if (this.olhoDireitoBordaDireita) {
      this.formFilter.value.olhoDireitoBordaDireita = evt.clientX;
      this.formFilter.value.olhoDireitoBordaDireita = evt.clientX;
      this.formFilter.controls['olhoDireitoBordaDireita'].setValue(evt.clientX);
      this.olhoDireitoBordaDireita = false;
      this.cdRef.detectChanges();
    }

  }

  calculaMediaIrisOlhos() {
    let olhoEsquerdoDiferenca = this.formFilter.value.olhoEsquerdoBordaDireita - this.formFilter.value.olhoEsquerdoBordaEsquerda;
    let olhoDireitoDiferenca = this.formFilter.value.olhoDireitoBordaDireita - this.formFilter.value.olhoDireitoBordaEsquerda;
    let media = Number((olhoDireitoDiferenca + olhoEsquerdoDiferenca) / 2).toFixed(2);
    this.mediaIris = media;
  }

  resolveDimensaoOculos() {
    let tamanhoHorizontalBulget = 140;
    let tamanhoVerticalBulget = 45;

    let tamanhoHorizontalBulgetReal = Number((this.mediaIris * tamanhoHorizontalBulget) / 11.5).toFixed(0);
    let tamanhoVerticalBulgetReal = Number((this.mediaIris * tamanhoVerticalBulget) / 11.5).toFixed(0);

    this.tamanhoHorizontalOculos = Number(tamanhoHorizontalBulgetReal);
    this.tamanhoVerticalOculos = Number(tamanhoVerticalBulgetReal);

  }

  selectEyesPosition(value: number) {
    switch (value) {
      case 1:
        this.olhoEsquerdoBordaEsquerda = true;
        break;
      case 2:
        this.olhoEsquerdoBordaDireita = true;
        break;
      case 3:
        this.olhoDireitoBordaEsquerda = true;
        break;
      case 4:
        this.olhoDireitoBordaDireita = true;
        break;
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async ngOnInit() {

    this.imageFile = this.data.data.file;
    this.imageDisplay = this.data.data.src
    this.imageFile = await this.dataURLtoFile(this.imageDisplay, 'siveon.png');
  }

  dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
  }



  sendImage() {
    const formData = new FormData();
    formData.append('image', this.imageFile);
    const url = environment.apiSiveonDev + 'resolve/mensure/glasses';
    const body = formData;
    this.service.sendimageApiSiveonTechnical(url, body, 'regua'
      , this.posicaoVerticalOculos, this.posicaoHorizontalOculos, this.tamanhoVerticalOculos, this.tamanhoHorizontalOculos)
      .toPromise()
      .then(response => {
        this.imageDisplay = 'data:image/png;base64,' + response;
      }).catch(error => {

    });
  }

  moverOculosParaCima() {
    this.posicaoVerticalOculos = this.posicaoVerticalOculos + 0.01;
    this.sendImage();
  }

  moverOculosParaBaixo() {
    this.posicaoVerticalOculos = this.posicaoVerticalOculos - 0.01;
    this.sendImage();
  }

  moverOculosParaDireita() {
    this.posicaoHorizontalOculos = this.posicaoHorizontalOculos + 1;
    this.sendImage();
  }

  moverOculosParaEsquerda() {
    this.posicaoHorizontalOculos = this.posicaoHorizontalOculos - 1;
    this.sendImage();
  }

  // sendImage() {
  //   const formData = new FormData();
  //   formData.append('image', this.image);
  //   const url = environment.apiSiveonDev + 'resolve/sampler/glasses';
  //   console.log('url', url);
  //   const body = formData;
  //   console.log('body', body);
  //   this.service.sendimageApiSiveon(url, body, '1f46f3438173403e8c01efec34bbc14e')
  //     .toPromise()
  //     .then(response => {
  //       this.data.data.src = 'data:image/png;base64,' + response;
  //     }).catch(error => {
  //     console.log('erro', error);
  //   });
  // }


}
