export class Provador {

  logo: string;
  codigoOculos: string;
  corDeFundo: string;
  corTituloBemVindo: string;
  corCardExterno: string;
  corCardInterno: string;
  corSubtitulo: string;
  corInstrucoes: string;
  corIconesInstrucoes: string;
  corFundoBotaoAdicionarImagem: string;
  corTextoAdicionarImagem: string;
  corIconeAdicionarImagem: string;
  corTextoRetornarProduto: string;
  corIconeRetornarProduto: string;
  corFundoBotaoEnviarImagem: string;
  corTextoEnviarImagem: string;
  corIconeEnviarImagem: string;
  corFundoBotaoNovaImagem: string;
  corTextoNovaImagem: string;
  corIconeNovaImagem: string;
  corFundoBotaoContinuarCompra: string;
  corTextoContinuarCompra: string;
  corIconeContinuarCompra: string;
  linkRedirecionamento: string;

  // tslint:disable-next-line:max-line-length
  constructor(logo: string, codigoOculos: string, corDeFundo: string, corTituloBemVindo: string, corCardExterno: string,
              corCardInterno: string, corSubtitulo: string, corInstrucoes: string, corIconesInstrucoes: string,
              corFundoBotaoAdicionarImagem: string, corTextoAdicionarImagem: string, corIconeAdicionarImagem: string,
              corTextoRetornarProduto: string, corIconeRetornarProduto: string, corFundoBotaoEnviarImagem: string,
              corTextoEnviarImagem: string, corIconeEnviarImagem: string, corFundoBotaoNovaImagem: string,
              corTextoNovaImagem: string, corIconeNovaImagem: string, corFundoBotaoContinuarCompra: string,
              corTextoContinuarCompra: string, corIconeContinuarCompra: string, linkRedirecionamento: string) {
    this.codigoOculos = codigoOculos ? codigoOculos : '';
    this.logo = logo ? logo : '../../assets/SIVEON-AZUL.png';
    this.corDeFundo = corDeFundo ? corDeFundo : '#ffffff';
    this.corTituloBemVindo = corTituloBemVindo ? corTituloBemVindo : '#1C3E74';
    this.corCardExterno = corCardExterno ? corCardExterno : '#ffffff';
    this.corCardInterno = corCardInterno ? corCardInterno : '#F3F3F3';
    this.corSubtitulo = corSubtitulo ? corSubtitulo : '#1C3E74';
    this.corInstrucoes = corInstrucoes ? corInstrucoes : 'black';
    this.corIconesInstrucoes = corIconesInstrucoes ? corIconesInstrucoes : '#F88125';
    this.corFundoBotaoAdicionarImagem = corFundoBotaoAdicionarImagem ? corFundoBotaoAdicionarImagem : '#F88125';
    this.corTextoAdicionarImagem = corTextoAdicionarImagem ? corTextoAdicionarImagem : '#ffffff';
    this.corIconeAdicionarImagem = corIconeAdicionarImagem ? corIconeAdicionarImagem : '#ffffff';
    this.corTextoRetornarProduto = corTextoRetornarProduto ? corTextoRetornarProduto : 'black';
    this.corIconeRetornarProduto = corIconeRetornarProduto ? corIconeRetornarProduto : 'black';
    this.corFundoBotaoEnviarImagem = corFundoBotaoEnviarImagem ? corFundoBotaoEnviarImagem : '#F88125';
    this.corTextoEnviarImagem = corTextoEnviarImagem ? corTextoEnviarImagem : '#ffffff';
    this.corIconeEnviarImagem = corIconeEnviarImagem ? corIconeEnviarImagem : '#ffffff';
    this.corFundoBotaoNovaImagem = corFundoBotaoNovaImagem ? corFundoBotaoNovaImagem : '#F88125';
    this.corTextoNovaImagem = corTextoNovaImagem ? corTextoNovaImagem : '#ffffff';
    this.corIconeNovaImagem = corIconeNovaImagem ? corIconeNovaImagem : '#ffffff';
    this.corFundoBotaoContinuarCompra = corFundoBotaoContinuarCompra ? corFundoBotaoContinuarCompra : '#F88125';
    this.corTextoContinuarCompra = corTextoContinuarCompra ? corTextoContinuarCompra : '#ffffff';
    this.corIconeContinuarCompra = corIconeContinuarCompra ? corIconeContinuarCompra : '#ffffff';
    this.linkRedirecionamento = linkRedirecionamento ? linkRedirecionamento : null;
  }
}
