import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, ViewChild} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {FormBuilder} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {CustomFilterContentComponent} from '../../components/filter/custom-filter-content.component';
import {MatDialog} from '@angular/material/dialog';
import {BreakpointObserver} from '@angular/cdk/layout';
import {PermissaoService} from '../../services/permissao.service';
import * as _ from 'lodash';
import {ProvadorService} from '../../services/provador.service';
import {ProvadorModalComponent} from './provador-modal.component';


@Component({
  selector: 'provadores-list',
  templateUrl: './provadores-list.component.html',
  styleUrls: ['./provadores-list.component.css']
})
export class ProvadoresListComponent implements AfterViewInit {
  formFilter = this.formBuilder.group({
    'term': [''],
  });
  loading = false;
  searchEventEmitter: EventEmitter<any> = new EventEmitter();
  term: string = '';
  totalElements: any = null;
  filtroOculos: any[] = [
    {
      descricao: 'Descricao',
      value: 'descricao',
      keys: ['descricao'],
      type: 'STRING'
    },
  ];

  @ViewChild(CustomFilterContentComponent) customFilterContentComponent: CustomFilterContentComponent;

  constructor(private authService: AuthService,
              private formBuilder: FormBuilder,
              private changeDetectorRef: ChangeDetectorRef,
              private breakpointObserver: BreakpointObserver,
              private provadorService: ProvadorService,
              private dialog: MatDialog,
              private permissionService: PermissaoService
  ) {
    this.searchEventEmitter.subscribe(async () => {
      this.list();
    });
  }

  displayedColumns = ['descricao', 'vigente', 'edit'];
  dataSource = new MatTableDataSource<any>([]);
  permissions = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  async ngAfterViewInit() {
    this.list();
    this.dataSource.paginator = this.paginator;
  }

  async list() {
    this.loading = true;
    let page = await this.provadorService.list(0, 999, this.customFilterContentComponent.buildParamsFilter());
    this.dataSource.data = page.content;
    this.loading = false;
  }

  async search() {
    if (this.formFilter.get('term').value) {
      this.term = this.formFilter.get('term').value;
      this.paginator.pageIndex = 0;
    } else {
      this.term = '';
    }
    await this.list();
  }

  open(bean?) {
    const isMobile = this.breakpointObserver.isMatched('(max-width: 600px)');
    const dialogConfig = {
      data: {bean},
      width: isMobile ? null : '60vw',
      minWidth: isMobile ? '100vw' : '30vw',
      minHeight: isMobile ? '80vh' : '80vh',
      position: isMobile ? {
        bottom: '0',
      } : null

    };

    this.dialog.open(ProvadorModalComponent, dialogConfig);


    this.dialog.afterAllClosed.subscribe(() => {
      this.list();
    });
  }

  protected readonly _ = _;
}
