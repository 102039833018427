import {Component, Input, ViewChild} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MatDatepicker} from '@angular/material/datepicker';


@Component({
  selector: 'custom-filter-content',
  templateUrl: './custom-filter-content.component.html',
  styleUrls: ['./custom-filter-content.component.css']
})
export class CustomFilterContentComponent {


  @Input()
  searchEventEmitter;

  @ViewChild(MatDatepicker) picker: MatDatepicker<any>;

  @Input()
  options: any[] = [];

  showInputCalendar: boolean = false;
  showInputEnum: boolean = false;
  showInputText: boolean = true;
  mapItens = new Map();

  created = null;
  updated = null;

  formFilter = this.formBuilder.group({
    'term': [''],
    'options': []
  });

  status: any[] = [
    {
      value: true,
      descricao: 'Ativo'
    },
    {
      value: false,
      descricao: 'Inativo'
    },
  ];


  constructor(private formBuilder: FormBuilder) {
  }

  customSearchComponent() {
    this.addItem();
    this.searchEventEmitter.emit();
  }

  addItem() {
    let value = this.formFilter.value;
    if (value.term) {
      this.mapItens.set(value.options.value, value);
      this.formFilter.setValue({term: '', options: value.options});
    }
  }

  async removeItem(key) {
    this.mapItens.delete(key);
    if (key.includes('created')) {
      this.created = null;
    } else if (key.includes('updated')) {
      this.updated = null;
    }
    this.searchEventEmitter.emit();
  }

  buildParamsFilter() {
    let filterParts = [];
    this.mapItens.forEach(value => {
      value.options.keys.forEach(keys => {
        filterParts.push(`{${keys} : ${value.term}}`);
      });
    });
    return filterParts.join(',');
  }

  resolveStatus(status) {
    if (status.includes('Ativo')) {
      return true;
    } else {
      return false;
    }
  }

  resolveDates(key, value) {
    let dt = new Date(value.term);
    let formatDate: string = dt.toISOString().replace(dt.toISOString().substring(dt.toISOString().indexOf('.'), dt.toISOString().indexOf('Z') + 1), '');
    if (key.includes('created')) {
      this.created = formatDate;
    } else {
      this.updated = formatDate;
    }
    return formatDate;
  }

  getCreated() {
    return this.created;
  }

  getUpdated() {
    return this.updated;
  }

  valuesEnum = [];

  controlTypeInputs(option: any) {
    if (option.type.includes('STRING')) {
      this.showInputCalendar = false;
      this.showInputEnum = false;
      this.showInputText = true;
    } else if (option.type.includes('ENUM')) {
      this.showInputEnum = true;
      this.showInputText = false;
      this.valuesEnum = option.opcoes;
    }


  }


}
