import {Component, OnInit, ViewChild} from '@angular/core';
import {CameraComponent} from '../../components/camera/camera.component';

@Component({
  selector: 'app-camera-dawnload',
  templateUrl: './dawnload-image-camera.component.html',
  styleUrls: ['./dawnload-image-camera.component.css']
})
export class DawnloadImageCameraComponent implements OnInit {

  @ViewChild(CameraComponent) cameraComponent: CameraComponent;

  ngOnInit() {
  }


  async capture() {
    await this.cameraComponent.capture();
    let imagePicture = await this.cameraComponent.fileImage;
    this.dawnloadImage(imagePicture, this.cameraComponent.mimyType);
  }

  dawnloadImage(image: any, mymeType: any) {
    const url = window.URL.createObjectURL(new Blob([image]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `image- ${new Date().toLocaleString('pt-BR', {timeZone: 'America/Sao_Paulo'})}.` + mymeType.substring(6));
    document.body.appendChild(link);
    link.click();
  }


}
