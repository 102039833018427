import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-menu-top-bar',
  templateUrl: './menu-top-bar.component.html',
  styleUrls: ['./menu-top-bar.component.css']
})
export class MenuTopBarComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  goToAbout() {
    this.router.navigate(['/sobre']);
  }
  goToProject() {
    this.router.navigate(['/projetos']);
  }
  goToContact() {
    this.router.navigate(['/contato']);
  }

}
