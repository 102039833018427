import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {LayoutProvador, Oculos, Pageable, Tenant} from '../shared/models';


@Injectable({
  providedIn: 'root'
})
export class TenantService {


  constructor(public http: HttpClient) {

  }

  async list(page: number = 1, size: number = 10, filter: string) {
    const obj: any = {page, size,filter};

    const response = await this.http.get<Pageable<Tenant>>(environment.apiSiveonDev + '/tenant', {params: obj}).toPromise();
    return response;
  }

  async get(id: number) {
    const response = await this.http.get<Tenant>(environment.apiSiveonDev + '/tenant/' + id,).toPromise();
    return response;
  }



  async save(value: any) {
    const result = await this.http.post<Tenant>(environment.apiSiveonDev + `/tenant`, value).toPromise();
    return result;
  }
}
