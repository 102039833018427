import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.css']
})
export class ColorPickerComponent {
  public hue: string;
  public color: string;

  @Output()
  colorSelect: EventEmitter<string> = new EventEmitter(true);

  constructor(public dialogRef: MatDialogRef<ColorPickerComponent>,
              @Inject(MAT_DIALOG_DATA) public data,

  ) {


  }
  changeColor($event: string) {
    this.color = $event;
    this.colorSelect.emit(this.color);
  }

  fecharModal() {
    this.dialogRef.close(this.color);
  }

}
