
export class LayoutProvador{
  logo: string;
  id:number;
  codigo: string;
  parametroAjusteProvador: string;
  widthLogo: string;
  heightLogo: string;
  marginLeftLogo: string;
  marginTopLogo: string;
  fontColorWelcome: string;
  backgroundColorFirstCard: string;
  text1: string;
  boxShadowWelcome: string;
  backgroundColorButtonAddImage: string;
  fontColorButtonAddImage: string;
  iconColorButtonAddImage: string;
  iconColorReturn: string;
  fontColorReturn: string;
  fontColorTitleSecond: string;
  backgroundColorSecondCard: string;
  iconsInstrucaoColor: string;
  fontColorAddToGalery: string;
  backgroundColorAddToGalery: string;
  colorIconGaleria: string;
  fontColorAddToCamera: string;
  backgroundColorAddToCamera: string;
  colorIconCamera: string;
  backgroundColorbuttonSend: string;
  fontColorButtonSend: string;
  iconColorButtonSend: string;
  backgroundColorThirdCard: string;
  backgroundColorReturnPhoto: string;
  colorIconReturnPhoto: string;
  fontColorReturnPhoto: string;
  backgroundColorGoBuy: string;
  colorIconGoBuy: string;
  fontColorGoBuy: string;
  linkBuyGlass: string;
  tenant: string;
  fundoPagina: string;

}

export class Context{
  username:string;
  domain:string;
}

export class Oculos{
  alturaArmacao: string;
  codigoSiveon: string;
  codigoIntegracao: string;
  fatorConversaoAltura: string;
  fatorConversaoLargura: string;
  id: number;
  larguraArmacao: any;
  linkImage:  string;
  linkProduto:  string;
  situacao:  string;
  modelo: string;
  valorAjusteProvador: string;
}

export class Pageable<T> {
  last: boolean = false;
  totalElements: number;
  totalPages: number;
  number: number = 1;
  size: number = 10;
  content: T[] = [];

}

export class Tenant{
  id:number;
  domain: string;
  nome: string;
}

export class Usuario{
  id:number;
  username: string;
  password: string;
}


