import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {LayoutProvador, Oculos, Pageable} from '../shared/models';


@Injectable({
  providedIn: 'root'
})
export class InclusaoOculosService {


  constructor(public http: HttpClient) {

  }

  async save(body) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    const response = await this.http.post(environment.apiSiveonDev + '/solicitacao/upload', body, {headers:headers}).toPromise();
    return response;
  }


  async getImage(id) {
    const response = await this.http.get(environment.apiSiveonDev + '/oculos/bucket/image/' + id).toPromise();
    return response;
  }

  async getImageOculosByCodigo(codigo: string) {
    let obj:any = {};
    obj.codigo = codigo;
    const response = await this.http.get(environment.apiSiveonDev + '/oculos/bucket/image',{params: obj}).toPromise();
    return response;
  }
}
