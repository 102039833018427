import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {OculosService} from '../../services/oculos.service';
import {ListOculosComponent} from '../../components/listagem-oculos/list-oculos.component';


@Component({
  selector: 'app-painel-administrativo',
  templateUrl: './painel-administrativo.component.html',
  styleUrls: ['./painel-administrativo.component.css']
})
export class PainelAdministrativoComponent {

  @ViewChild('applistoculos') listoculos: ListOculosComponent;


  tenantId:any = null

  constructor(public route: Router,
              public service: AuthService,
              public oculosService: OculosService,
              private routeActivate: ActivatedRoute) {

  }


  ngOnInit(): void {
    this.tenantId = this.routeActivate.snapshot.paramMap.get('tenantid');

  }

  async open() {
    await this.route.navigate(['/measurement-tool']);
  }



  showListOculos:boolean = false;
  showConfiguracaoOculos(){
    this.showListOculos = true
  }


}
