import {ChangeDetectorRef, Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {FormBuilder} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {Pageable, Tenant} from '../../shared/models';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog} from '@angular/material/dialog';
import {BreakpointObserver} from '@angular/cdk/layout';
import {DragdropUploadComponent} from '../../components/dragdropupload/dragdrop-upload.component';
import {TenantService} from '../../services/tenant.service';
import {OculosModalComponent} from '../oculos/oculos-modal.component';
import {TenantModalComponent} from './tenant-modal.component';
import {Router} from '@angular/router';
import {CustomFilterContentComponent} from '../../components/filter/custom-filter-content.component';

@Component({
  selector: 'app-tenant-list',
  templateUrl: './tenant-list.component.html',
  styleUrls: ['./tenant-list.component.css']
})
export class TenantListComponent implements OnInit {
  formFilter = this.formBuilder.group({
    'term': [''],
  });
  displayedColumns = ['nome', 'domain', 'editar','acessar'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  listOculos: any[] = [];
  page: Pageable<Tenant>;
  term: string = '';
  loading: boolean = true;
  @ViewChild(DragdropUploadComponent) teste: DragdropUploadComponent;

  constructor( private router: Router,
              private formBuilder: FormBuilder,
              private changeDetectorRef: ChangeDetectorRef,
              private tenantService: TenantService,
               private dialog: MatDialog,
               private breakpointObserver: BreakpointObserver

  ) {
    this.searchEventEmitter.subscribe(async () => {
      this.list();
    });
  }


  async ngAfterViewInit() {
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 10;
    this.paginator.page.subscribe(() => this.list());

    await this.list();
    this.changeDetectorRef.detectChanges();
  }

  async list() {
    const pageIndex = this.paginator.pageIndex;
    const pageSize = this.paginator.pageSize;
    this.term = 'tenant.nome like souza'
    this.page = await this.tenantService.list(pageIndex, pageSize, this.customFilterContentComponent.buildParamsFilter());
    this.dataSource.data = this.page.content;
    this.loading = false;
  }

  async search() {

    if (this.formFilter.get('term').value) {
      this.term = this.formFilter.get('term').value;
      this.paginator.pageIndex = 0;
    } else {
      this.term = '';
    }
    await this.list();
  }



  async ngOnInit() {

  }
  open(bean?) {
    this.router.navigate(['painel-administrativo', bean.id]);
  }

  newTenant(bean?){
    const isMobile = this.breakpointObserver.isMatched('(max-width: 600px)');

    const dialogConfig = {
      width: isMobile ? '100%' : '800px',
      height: isMobile ? '100%' : '800px',
      data:  {bean:bean},
    };
    this.dialog.open(TenantModalComponent, dialogConfig);

    this.dialog.afterAllClosed.subscribe(() => {
      this.list();
    });
  }
  @ViewChild(CustomFilterContentComponent) customFilterContentComponent: CustomFilterContentComponent;
  searchEventEmitter: EventEmitter<any> = new EventEmitter();
  filtroTenant: any[] = [
    {
      descricao: "Dominio",
      value: "domain",
      keys: ['domain'],
      type:"STRING"
    }, {
      descricao: "Nome",
      value: "nome",
      keys: ['nome'],
      type:"STRING"
    },


  ];

}
