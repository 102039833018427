import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {OculosService} from '../../services/oculos.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './home-revenda.component.html',
  styleUrls: ['./home-revenda.component.css']
})
export class HomeRevendaComponent {
  statusEnviar = true;
  statusCarregado = false;
  buttonColorEnviar: string = '#FFC5C8';
  image: File = null;
  previewImage = null;
  sendImage = false;
  glasse: string = null;
  height: number = 0.18;

  formLogin: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', Validators.required)
  });

  constructor(public route: Router,
              public service: AuthService,
              public oculosService: OculosService) {

  }


  ngOnInit(): void {
  }

  async open() {
    await this.route.navigate(['/measurement-tool']);
  }

  async openCalibrarMedida() {
    await this.route.navigate(['/measurement-calibrate-tool']);
  }

  async openRelatorios() {
    await this.route.navigate(['/relatorios']);
  }

  async openListagemConfiguracaoOculos() {
    await this.route.navigate(['/oculos']);
  }
}
