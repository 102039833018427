import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {OculosService} from '../../services/oculos.service';
import {Oculos} from '../../shared/models';
import {FormBuilder} from '@angular/forms';
import {ImageUploadService} from '../../services/image-upload.service';
import {MedidasService} from '../../services/medidas.service';


@Component({
  selector: 'app-upload-image',
  templateUrl: 'upload-image.component.html',
  styleUrls: ['upload-image.component.css']
})
export class UploadImageComponent implements OnInit {

  @Input()
  drawer;
  logo: string = './assets/SIVEON.png';
  loaded = false;
  listOculos: Oculos[] = [];

  @Output()
  selected = new EventEmitter<any>();

  @Output()
  value: Oculos;

  @Output()
  selectedFile: ImageSnippet;

  @Output()
  image: File = null;

  constructor(private authService: AuthService,
              private oculosService: OculosService,
              private formBuilder: FormBuilder,
              private changeDetectorRef: ChangeDetectorRef,
              private imageUploadService: ImageUploadService,
              private service: MedidasService
  ) {

  }

  async ngOnInit() {
    await this.list();
  }

  async list() {

  }


  async onFileUpload(event: any) {
    const file: File = event.target.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', async (event: any) => {
      this.selectedFile = new ImageSnippet(event.target.result, file);
      this.image = this.selectedFile.file;
      await this.resolveSizeImage();
    });
    reader.readAsDataURL(file);


  }

  async resolveSizeImage() {
    const formData = new FormData();
    formData.append('image', this.image);
    const body = formData;
    return await this.service.resize(body)
      .then(response => {
        this.selectedFile = new ImageSnippet('data:image/png;base64,' + response, this.dataURLtoFile('data:image/png;base64,' + response, Date.now().toString() + '.png'),);
        this.imageUploadService.setImage(this.selectedFile);
      }).catch(error => {

      });

  }

  dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
  }


}

class ImageSnippet {
  constructor(public src: string, public file: File) {
  }
}
