import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {OculosService} from '../../services/oculos.service';
import {PermissaoService} from '../../services/permissao.service';
import * as _ from 'lodash';


@Component({
  selector: 'app-dashboard',
  templateUrl: './home-lojista.component.html',
  styleUrls: ['./home-lojista.component.css']
})
export class HomeLojistaComponent {
  permissions = [];
  loading = true;
  usuario: string = null;

  formLogin: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', Validators.required)
  });

  constructor(public route: Router,
              public service: AuthService,
              public oculosService: OculosService,
              public permissionService: PermissaoService) {

  }


  async ngOnInit() {
    this.permissions = await this.permissionService.getPermissoes();
    this.loading = false;
  }

  async open() {
    await this.route.navigate(['/measurement-tool']);
  }

  async openDashboard() {
    await this.route.navigate(['/dashboard']);
  }

  async openRelatorios() {
    await this.route.navigate(['/relatorios']);
  }

  async openListagemConfiguracaoOculos() {
    await this.route.navigate(['/oculos']);
  }

  async openProvadores() {
    await this.route.navigate(['/listagem-templates-provador']);
  }

  protected readonly _ = _;
}
