import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {OculosService} from '../../services/oculos.service';
import {Oculos} from '../../shared/models';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {environment} from '../../environments/environment';
import {ImageUploadService} from '../../services/image-upload.service';
import {MatDialog} from '@angular/material/dialog';
import {HttpResponseInterceptorComponent} from '../httpresponseinterceptor/http-response-interceptor.component';


@Component({
  selector: 'app-mark-iris-image',
  templateUrl: 'mark-iris-image.component.html',
  styleUrls: ['mark-iris-image.component.css']
})
export class MarkIrisImageComponent implements OnInit {
  expanded = false;

  @Output() open: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  @Input()
  imegeDisplay;

  @Output()
  media;
  distanciaOriginalOlhoEsquerdo: any;
  distanciaOriginalOlhoDireito: any;
  distanciaOriginalOlhoEsquerdoZoom: any;
  distanciaOriginalOlhoDireitoZoom: any;
  olhoEsquerdoBordaEsquerda: boolean = false;
  olhoEsquerdoBordaDireita: boolean = false;
  olhoDireitoBordaEsquerda: boolean = false;
  olhoDireitoBordaDireita: boolean = false;
  showInstrucao1: boolean = false;
  showInstrucao2: boolean = false;
  showInstrucao3: boolean = false;
  showInstrucao4: boolean = false;
  showInstrucao5: boolean = false;
  panelOpenState = false;
  imageFile: File = null;
  imageDisplay: any;
  mediaIris: any;
  tamanhoHorizontalOculos: number = 200;
  tamanhoVerticalOculos: number = 50;
  posicaoVerticalOculos: number = 0.0;
  posicaoHorizontalOculos: number = 0;

  formFilter = this.formBuilder.group({
    olhoEsquerdoBordaEsquerda: new FormControl('', Validators.required),
    olhoEsquerdoBordaDireita: new FormControl('', Validators.required),
    olhoDireitoBordaEsquerda: new FormControl('', Validators.required),
    olhoDireitoBordaDireita: new FormControl('', Validators.required),

  });


  constructor(private formBuilder: FormBuilder,
              private dialog: MatDialog,
  ) {

  }

  async ngOnInit() {

  }

  zoomLevel = 1;

  zoomIn(imageElement: HTMLElement) {
    this.zoomLevel += 0.2;
    imageElement.style.transform = `scale(${this.zoomLevel})`;
  }

  zoomOf(imageElement: HTMLElement) {
    this.zoomLevel -= 0.2;
    imageElement.style.transform = `scale(${this.zoomLevel})`;
  }

  calcDistanciaOriginalOlhoEsquerdo() {
    this.distanciaOriginalOlhoEsquerdo = Math.abs(this.formFilter.value.olhoEsquerdoBordaEsquerda - this.formFilter.value.olhoEsquerdoBordaDireita);

  }

  calcDistanciaOriginalOlhoEsquerdoZoom() {
    this.distanciaOriginalOlhoEsquerdoZoom = Math.abs(this.formFilter.value.olhoEsquerdoBordaEsquerda / this.zoomLevel) - (this.formFilter.value.olhoEsquerdoBordaDireita / this.zoomLevel);

  }

  calcDistanciaOriginalOlhoDireito() {
    this.distanciaOriginalOlhoDireito = Math.abs(this.formFilter.value.olhoDireitoBordaEsquerda - this.formFilter.value.olhoDireitoBordaDireita);

  }

  calcDistanciaOriginalOlhoDireitoZoom() {
    this.distanciaOriginalOlhoDireitoZoom = Math.abs(this.formFilter.value.olhoDireitoBordaEsquerda / this.zoomLevel) - (this.formFilter.value.olhoDireitoBordaDireita / this.zoomLevel);

  }

  selectEyesPosition(value: number) {
    switch (value) {
      case 1:
        this.olhoEsquerdoBordaEsquerda = true;
        break;
      case 2:
        this.olhoEsquerdoBordaDireita = true;
        break;
      case 3:
        this.olhoDireitoBordaEsquerda = true;
        break;
      case 4:
        this.olhoDireitoBordaDireita = true;
        break;
    }

  }

  getDistancePixels(evt) {
    if (this.olhoEsquerdoBordaDireita) {
      this.formFilter.value.olhoEsquerdoBordaDireita = evt.clientX;
      this.formFilter.controls['olhoEsquerdoBordaDireita'].setValue(evt.clientX);
      this.olhoEsquerdoBordaDireita = false;


    }
    if (this.olhoEsquerdoBordaEsquerda) {
      this.formFilter.value.olhoEsquerdoBordaEsquerda = evt.clientX;
      this.formFilter.controls['olhoEsquerdoBordaEsquerda'].setValue(evt.clientX);
      this.olhoEsquerdoBordaEsquerda = false;
    }

    if (this.olhoDireitoBordaEsquerda) {
      this.formFilter.value.olhoDireitoBordaEsquerda = evt.clientX;
      this.formFilter.value.olhoDireitoBordaEsquerda = evt.clientX;
      this.formFilter.controls['olhoDireitoBordaEsquerda'].setValue(evt.clientX);
      this.olhoDireitoBordaEsquerda = false;

    }
    if (this.olhoDireitoBordaDireita) {
      this.formFilter.value.olhoDireitoBordaDireita = evt.clientX;
      this.formFilter.value.olhoDireitoBordaDireita = evt.clientX;
      this.formFilter.controls['olhoDireitoBordaDireita'].setValue(evt.clientX);
      this.olhoDireitoBordaDireita = false;

    }
  }

  togglePanel(position: number, show: boolean) {
    if (position === 1) {
      this.showInstrucao1 = show;
    }
    if (position === 2) {
      this.showInstrucao2 = show;
    }
    if (position === 3) {
      this.showInstrucao3 = show;
    }
    if (position === 4) {
      this.showInstrucao4 = show;
    }
    if (position === 5) {
      this.showInstrucao5 = show;
    }

    this.expanded = !this.expanded;
  }

  sendImage() {
    if (this.validateForm()) {
      return;
    }
    this.calcDistanciaOriginalOlhoDireitoZoom();
    this.calcDistanciaOriginalOlhoEsquerdoZoom();
    this.media = Math.abs((this.distanciaOriginalOlhoEsquerdoZoom + this.distanciaOriginalOlhoDireitoZoom) / 2);
    this.open.emit(this.media);

  }


  validateForm(): boolean {
    let invalid = false;
    if (this.formFilter.invalid) {
      invalid = true;
      const dialogRef = this.dialog.open(HttpResponseInterceptorComponent, {
        data: {
          message: 'Prencha todas as posiçoes !',
          error: true
        },
        position: {top: '8px'}

      });
      setTimeout(() => {
        dialogRef.close();
      }, 1000);
    }
    return invalid;
  }
}

class ImageSnippet {
  constructor(public src: string, public file: File) {
  }
}
