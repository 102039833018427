import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

 
import {environment} from '../environments/environment';
import { Pageable } from 'src/shared/models';
 


@Injectable({
  providedIn: 'root'
})
export class LeadsService {


  constructor(public http: HttpClient) {

  }

  async list(offset: number = 1, limit: number = 10, filter:any) {
    const obj: any = {offset, limit,filter};
    const response = await this.http.get<Pageable<any>>(environment.apiSiveonDev + '/leads', {params: obj}).toPromise();
    return response;
  }

 

  async get(id: number) {
    const response = await this.http.get<any>(environment.apiSiveonDev + '/leads/' + id,).toPromise();
    return response;
  }

  async save(value: any) {
    const result = await this.http.post<any>(environment.apiSiveonDev + `/leads`, value).toPromise();
    return result;
  }
 
 
}
