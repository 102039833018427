import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {Context, LayoutProvador} from '../shared/models';
import {Router} from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService {


  constructor(public http: HttpClient,
              public route: Router,) {
  }

  url: string = environment.apiSiveonDev;

  async login(body: any) {
    this.removeToken();
    const response = await this.http.post(this.url + '/login', body, {
      responseType: 'text',
      observe: 'response',

    }).toPromise();
    this.setToken(response.body);

  }

  async context() {
    const response = await this.http.get<Context>(this.url + '/core/context', {}).toPromise();
    return response;
  }

  private setToken(token) {
    localStorage.setItem('accessToken', token);
  }

  private removeToken() {
    localStorage.removeItem('token');
  }


  async logout() {
    this.removeToken();
    await this.route.navigate(['/login']);
  }

  async loginAdmin(body: any) {
    this.removeToken();
    const response = await this.http.post(this.url + '/login-adm', body, {
      responseType: 'text',
      observe: 'response',

    }).toPromise();
    this.setToken(response.body);

  }
}
