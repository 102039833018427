import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {LayoutProvador, Oculos, Pageable, Tenant, Usuario} from '../shared/models';


@Injectable({
  providedIn: 'root'
})
export class UsuarioService {


  constructor(public http: HttpClient) {

  }

  async list(page: number = 1, size: number = 10, term: string, tenantId) {
    const obj: any = {page, size, term};
    const response = await this.http.get(environment.apiSiveonDev + '/usuario/' + tenantId + '/tenant', {params: obj}).toPromise();
    return response;
  }

  async get(id: number) {
    const response = await this.http.get<Usuario>(environment.apiSiveonDev + '/usuario/' + id,).toPromise();
    return response;
  }


  async save(value: any,tenantId:any) {
    const result = await this.http.post<Usuario>(environment.apiSiveonDev + '/usuario/tenant/' + + tenantId , value).toPromise();
    return result;
  }
}
