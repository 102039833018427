import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {Context, LayoutProvador} from '../shared/models';
import {Router} from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class NotificationsService {


  constructor(public http: HttpClient,
              public route: Router,) {
  }

  url: string = environment.apiSiveonDev;


  async getAll() {
    const response = await this.http.get<any>(this.url + '/notificacao', {}).toPromise();
    return response;
  }

  async check(size) {
    let obj: any = {};
    obj.size = size;
    const response = await this.http.get<any>(this.url + '/notificacao/check', {params: obj}).toPromise();
    return response;
  }


  async markAsRead(id) {
    const response = await this.http.get<any>(this.url + '/notificacao/read/' + id, {}).toPromise();
    return response;
  }

  async markAllRead(notificacaoIds: any[]) {
    let response = await this.http.post<any>(this.url + '/notificacao/read/all', { notificacaoIds }).toPromise();
    return response;
  }
}
