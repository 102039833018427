import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '../../services/auth.service';
import {Provador} from '../../services/provador';
import {RelatoriosService} from '../../services/relatorios.service';


@Component({
  selector: 'relatorio-modal',
  templateUrl: './relatorio-modal.component.html',
  styleUrls: ['./relatorios-list.component.css']

})
export class RelatorioModalComponent implements OnInit {

  builder: Provador = null;
  fileIsLoad = false;
  loadingImage = false;
  loading = false;
  public preview: any = null;
  public imagemCarregada = false;
  edit: boolean;


  constructor(public dialogRef: MatDialogRef<any>,
              private authService: AuthService,
              @Inject(MAT_DIALOG_DATA) private data,
              private formBuilder: FormBuilder,
              private dialog: MatDialog,
              private service: RelatoriosService,
  ) {

  }


  formLayout = this.formBuilder.group({
    'id': [''],
    'descricao': [''],
    'url': [''],
    'vigente': [''],
  });

  ngOnInit() {
    if (this.data.bean != null) {
      this.formLayout.patchValue(this.data.bean);
      this.formLayout.patchValue({
        vigente: this.data.bean.vigente ? 'true' : 'false'
      });
    } else {
      this.formLayout.patchValue({
        vigente: 'false'
      });
    }
  }

  async save() {
    this.service.save(this.formLayout.value);
  }

  close(){
    this.dialogRef.close();
  }


}
