import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {Component, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class SnackMessageService {
  constructor(private snackBar: MatSnackBar) {
  }

// Exemplo de exibição de mensagem de sucesso
  showSuccessMessage(message: string) {
    this.snackBar.open(message, 'Fechar', {
      duration: 3000,
      panelClass: ['green-snackbar', 'login-snackbar'],
    });
  }


// Exemplo de exibição de mensagem de erro
  showErrorMessage(message: string) {
    this.snackBar.open(message, 'Fechar', {
      duration: 3000,
      panelClass: ['red-snackbar', 'login-snackbar'],
    });
  }
}
