import {ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../environments/environment';
import {ProvadorService} from '../../services/provador.service';
import {Router} from '@angular/router';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {DialogOverviewExampleDialog} from './popup-image.component';


@Component({
  selector: ' ',
  templateUrl: './area-medidas.component.html',
  styleUrls: ['./area-medidas.component.css']
})
export class AreaMedidasComponent implements OnInit {

  imageAdjust: boolean = false;
  showButtonSend: boolean = false;
  image: string;
  selectedFile: ImageSnippet;
  srcImageDisplay: any;
  olhoEsquerdoBordaEsquerda: boolean = false;
  olhoEsquerdoBordaDireita: boolean = false;


  constructor(private cdRef: ChangeDetectorRef,
              private formBuilder: FormBuilder,
              public dialog: MatDialog) {
  }

  ngOnInit() {

  }

  formFilter = this.formBuilder.group({
    olhoEsquerdoBordaEsquerda: new FormControl('',),
    olhoEsquerdoBordaDireita: new FormControl('',),

  });

  selectEyesPosition(value: number) {
    switch (value) {
      case 1:
        this.olhoEsquerdoBordaEsquerda = true;
        break;
      case 2:
        this.olhoEsquerdoBordaDireita = true;
        break;
    }

  }

  onFileUpload(event: any) {
    const file: File = event.target.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.selectedFile = new ImageSnippet(event.target.result, file);
      this.image = this.selectedFile.src;
      let obj: any = {};
      obj.file = file;
      obj.src = this.selectedFile.src;
      this.openDialog(obj);
      this.cdRef.detectChanges();
      this.imageAdjust = true;
    });
    reader.readAsDataURL(file);

  }

  getDistancePixels(evt) {

    if (this.olhoEsquerdoBordaDireita) {
      this.formFilter.value.olhoEsquerdoBordaDireita = evt.clientX;
      this.formFilter.controls['olhoEsquerdoBordaDireita'].setValue(evt.clientX);
      this.olhoEsquerdoBordaDireita = false;
      this.cdRef.detectChanges();

    }
    if (this.olhoEsquerdoBordaEsquerda) {
      this.formFilter.value.olhoEsquerdoBordaEsquerda = evt.clientX;
      this.formFilter.value.olhoEsquerdoBordaEsquerda = evt.clientX;
      this.formFilter.controls['olhoEsquerdoBordaEsquerda'].setValue(evt.clientX);
      this.olhoEsquerdoBordaEsquerda = false;
      this.cdRef.detectChanges();
    }
  }

  openDialog(data: any): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '1200px',
      height: '1200px',
      data: {data}
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }


  sendImage() {
    this.imageAdjust = true;
    const formData = new FormData();
    formData.append('image', this.image);
    const url = environment.apiSiveonDev + 'resolve/sampler/glasses';
    const body = formData;
    this.srcImageDisplay = this.image;
    // this.siveonService.sendimageApiSiveon(url, body, this.glasse)
    //   .toPromise()
    //   .then(response => {
    //     console.log(response);
    //     this.loading = false;
    //     this.srcImageDisplay = 'data:image/png;base64,' + response;
    //     let img = this.dataURLtoFile(this.srcImageDisplay, new Date().toLocaleString());
    //     // this.dawnloadImage(img, '.png');
    //   }).catch(error => {
    //   console.log('erro', error);
    // });
  }

}

class ImageSnippet {
  constructor(public src: string, public file: File) {
  }
}

