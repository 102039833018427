import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {OculosService} from '../../services/oculos.service';
import {FormBuilder, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {TenantService} from '../../services/tenant.service';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {Oculos, Pageable, Usuario} from '../../shared/models';
import {UsuarioService} from '../../services/usuario.service';
import {UsuarioModalComponent} from '../usuarios/usuario-modal.component';
import {BreakpointObserver} from '@angular/cdk/layout';
import {PermissaoModalComponent} from '../permissoes/permissao-modal.component';

@Component({
  selector: 'tenant-modal',
  templateUrl: './tenant-modal.component.html',

})
export class TenantModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) private data,
              private formBuilder: FormBuilder,
              private tenantService: TenantService,
              private usuarioService: UsuarioService,
              private breakpointObserver: BreakpointObserver,
              private dialog: MatDialog,
  ) {

  }

  displayedColumns = ['nome', 'permissions', 'edit'];
  dataSource = new MatTableDataSource<Usuario>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  listOculos: any[] = [];
  page: Pageable<Usuario>;
  loading: boolean = true;
  image: string = null;

  formOculos = this.formBuilder.group({
    'id': [''],
    'domain': ['', [Validators.required, Validators.pattern(/^[a-z0-9]+$/)]],
    'nome': [''],
  });


  async ngOnInit() {
    if (this.data.bean != null) {
      this.formOculos.markAsUntouched();
      this.formOculos.patchValue(this.data.bean);
      await this.listUsuarios();

    }

    this.loading = false;
  }

  async listUsuarios() {
    let object: any = await this.usuarioService.list(null, null, null, this.data.bean.id);
    this.dataSource.data = object;
  }

  async save() {
    await this.tenantService.save(this.formOculos.value);
    this.close();
  }

  close() {
    this.dialogRef.close();
  }


  openUsuario(bean?) {
    const isMobile = this.breakpointObserver.isMatched('(max-width: 600px)');

    const dialogConfig = {
      width: isMobile ? '100%' : '800px',
      height: isMobile ? '100%' : '800px',
      data: {
        bean: bean,
        tenantId: this.data.bean.id
      },
    };
    let matDialogRef = this.dialog.open(UsuarioModalComponent, dialogConfig);

    matDialogRef.afterClosed().subscribe(async () => {
      await this.listUsuarios();
    });

  }

  openPermissoes(bean?) {
    const isMobile = this.breakpointObserver.isMatched('(max-width: 600px)');

    const dialogConfig = {
      width: isMobile ? '100%' : '800px',
      height: isMobile ? '100%' : '800px',
      data: {
        bean: bean,
        tenantId: this.data.bean.id
      },
    };
    let matDialogRef = this.dialog.open(PermissaoModalComponent, dialogConfig);

    matDialogRef.afterClosed().subscribe(async () => {
      // await this.listUsuarios();
    });

  }


}
