import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {OculosService} from '../../services/oculos.service';
import {Oculos, Pageable} from '../../shared/models';
import {FormBuilder} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {RelatoriosService} from '../../services/relatorios.service';
import {MatPaginator} from '@angular/material/paginator';


@Component({
  selector: 'app-list-oculos',
  templateUrl: './list-oculos.component.html',
  styleUrls: ['./list-oculos.component.css']
})
export class ListOculosComponent implements OnInit {

  @Input()
  drawer;
  logo: string = './assets/SIVEON.png';

  @Output()
  selected = new EventEmitter<any>();

  @Output()
  value: Oculos;

  @Input()
  isAdmin: boolean =  false;

  @Input()
  tenantId: number = null;

  @Input()
  title: string = 'Ferramenta de medidas digital';

  @Input()
  relatorioVisualizacoes:boolean = false;

  displayedColumns = ['image','modelo', 'codigo','edit'];

  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  page: Pageable<Oculos>;
  term: string = "";
  loading = true;
  @Output() loadingEvent: EventEmitter<any> = new EventEmitter();
  constructor(private oculosService: OculosService,
              private formBuilder: FormBuilder,
              private changeDetectorRef: ChangeDetectorRef,

  ) {

  }
  formFilter = this.formBuilder.group({
    'term': [''],
  });
  async ngAfterViewInit() {
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 10;
    this.paginator.page.subscribe(() => this.list());
    await this.list();
    this.changeDetectorRef.detectChanges();
    this.loading = false;

  }

  async list() {
    const pageIndex = this.paginator.pageIndex ;
    const pageSize = this.paginator.pageSize;
    if(this.isAdmin){
      this.page = await this.oculosService.listAdmin(pageIndex, pageSize,this.term,this.tenantId);
    }else {
      this.page = await this.oculosService.list(pageIndex, pageSize,this.term);
    }

    this.dataSource.data = this.page.content;
    this.loading = false;
  }

  async search() {
    if (this.formFilter.get('term').value) {
      this.term = this.formFilter.get('term').value;
      this.paginator.pageIndex = 0;
    } else {
      this.term = '';
    }
    await this.list();
  }



  async ngOnInit() {

  }
  open(value: Oculos) {
    this.value = value;
    this.selected.emit(value);
  }
}
