import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Observable, Observer} from 'rxjs';

;

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.css']
})
export class CameraComponent implements AfterViewInit {


  @ViewChild('video')
  public video: ElementRef;

  @ViewChild('canvas')
  public canvas: ElementRef;

  @Input()
  layoutAdjust:string;

  captures: string[] = [];
  error: any;
  isCaptured: boolean;

  photo:any;
  mymeType:any;


  stream: any;
  myStream: any;

  name = 'Angular';
  mimyType;

  turnOffCam: boolean = false;

  widht;
  height;

  @Input()
  fileImage: File;

  async ngAfterViewInit() {
    await this.setupDevices();
  }

  async setupDevices() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: {ideal: 1080},
            height: {ideal: 1080},
            facingMode: 'user'
          }
        });
        if (this.stream) {
          let {width, height} = this.stream.getTracks()[0].getSettings();
          this.widht = width;
          this.height = height;
          this.video.nativeElement.srcObject = this.stream;
          this.video.nativeElement.play();
          this.error = null;
        } else {
          this.error = 'You have no output video device';
        }
      } catch (e) {
        this.error = e;
      }
    }
  }

  async capture() {
    this.drawImageToCanvas(this.video.nativeElement);
    this.captures.push(this.canvas.nativeElement.toDataURL('image/jpeg'));
    this.isCaptured = true;
    await this.resolveImage();

    await this.removeCurrent();
  }

  async resolveImage() {
    if (this.captures.length != 0) {
      for (let photo of this.captures) {
        this.mimyType = this.getMymeType(photo);
        this.fileImage = this.dataURLtoFile(photo, this.generateName(this.mimyType));
        this.dawnloadImage(this.fileImage, this.mimyType);


      }
    }

  }

  dawnloadImage(image: any, mymeType: any) {
    const url = window.URL.createObjectURL(new Blob([image]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `image- ${new Date().toLocaleString('pt-BR', {timeZone: 'America/Sao_Paulo'})}.` + mymeType.substring(6));
    document.body.appendChild(link);
    link.click();
    this.captures = [];
    this.mimyType = null;

  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type: mime});
  }

  getMymeType(dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1];
    return mime;
  }


  async removeCurrent() {
    // const stream = this.video.nativeElement.srcObject;
    // const tracks = stream.getTracks();
    // tracks[0].stop();
    // console.log(tracks);
    // tracks.forEach(function (track) {
    //   track.stop();
    // });
    this.captures = [];
    this.isCaptured = false;


  }

  setPhoto(idx: number) {
    this.isCaptured = true;
    var image = new Image();
    image.src = this.captures[idx];
    this.drawImageToCanvas(image);
  }

  drawImageToCanvas(image: any) {
    this.canvas.nativeElement
      .getContext('2d')
      .drawImage(image, 0, 0, this.widht, this.height);
  }


  private generateName(mymeType: any) {
    let type = mymeType.substring(6);
    return new Date().toISOString() + '.' + type;

  }
}
