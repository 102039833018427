import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './src/views/home/home.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {MatBadgeModule} from '@angular/material/badge';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MenuTopBarComponent} from './src/views/menu-top-bar/menu-top-bar.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {ProjetosComponent} from './src/views/projetos/projetos.component';
import {SobreNosComponent} from './src/views/sobre-nos/sobre-nos.component';
import {ContatoComponent} from './src/views/contato/contato.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {HomeDesktopComponent} from './src/views/banner-desktop/home-desktop.component';
import {ProvadorCoreComponent} from './src/components/provador/provador-core.component';
import {CameraComponent} from './src/components/camera/camera.component';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {DawnloadImageCameraComponent} from './src/views/imageDawnload/dawnload-image-camera.component';

import {AreaMedidasComponent} from './src/views/areaMedidas/area-medidas.component';
import {ProvadorDinamicoComponent} from './src/views/provador-dinamico/provador-dinamico.component';
import {DialogOverviewExampleDialog} from './src/views/areaMedidas/popup-image.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {LoadingComponent} from './src/components/loading/loading.component';
import {HttpInterceptorService} from './src/components/httpresponseinterceptor/httpInteceptor';
import {HttpResponseInterceptorComponent} from './src/components/httpresponseinterceptor/http-response-interceptor.component';
import {LoginModalComponent} from './src/views/login/login-modal.component';

import {TokenInterceptor} from './src/services/tokenInterceptor.service';
import {DashboardModalComponent} from './src/views/dashboard/dashboard-modal.component';
import {TopbarComponent} from './src/components/topbar/topbar.component';
import {MatMenuModule} from '@angular/material/menu';
import {ListOculosComponent} from './src/components/listagem-oculos/list-oculos.component';
import {MatGridListModule} from '@angular/material/grid-list';

import {StepperComponent} from './src/components/progress-bar/stepper.component';
import {MatStepperModule} from '@angular/material/stepper';
import {UploadImageComponent} from './src/components/upload-image/upload-image.component';
import {MarkIrisImageComponent} from './src/components/mark-iris-image/mark-iris-image.component';

import {OverlayPanelModule} from 'primeng/overlaypanel';
import {MoveOculosComponent} from './src/components/move-oculos/move-oculos.component';

import {DragDropModule} from '@angular/cdk/drag-drop';
import {FerramentaMedidasComponent} from './src/views/ferramenta-medidas/ferramenta-medidas.component';
import {FerramentaCalibracaoComponent} from './src/views/ferramenta-calibracao/ferramenta-calibracao.component';
import {RelatoriosListComponent} from './src/views/relatorios/relatorios-list.component';
import {MatTableModule} from '@angular/material/table';
import {OculosListComponent} from './src/views/oculos/oculos-list.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {OculosModalComponent} from './src/views/oculos/oculos-modal.component';
import {MatSnackBar, MatSnackBarModule} from '@angular/material/snack-bar';
import {DragdropUploadComponent} from './src/components/dragdropupload/dragdrop-upload.component';
import {DragDirective} from './src/components/dragdropupload/dragDrop.directive';
import {HiddenByPermissionDirective} from './src/directives/hidden-by-permission.directive';
import {HomeRevendaComponent} from './src/views/home-revenda/home-revenda.component';
import {TenantListComponent} from './src/views/tenants/tenant-list.component';
import {TenantModalComponent} from './src/views/tenants/tenant-modal.component';
import {PainelAdministrativoComponent} from './src/views/painel-administrativo/painel-administrativo.component';
import {MatSelectModule} from '@angular/material/select';
import {ProvadorConfigModalComponent} from './src/views/provador-configuracao/provador-config-modal.component';
import {SelecionaValoresProvadorModalComponent} from './src/views/personalizarprovador/seleciona-valores-provador-modal.component';
import {PersonalizaProvadorComponent} from './src/views/personalizarprovador/personaliza-provador.component';
import {ColorPickerModule} from './src/components/color-picker/color-picker.module';
import {UsuarioModalComponent} from './src/views/usuarios/usuario-modal.component';
import {MatChipsModule} from '@angular/material/chips';
import {PermissaoModalComponent} from './src/views/permissoes/permissao-modal.component';
import {CustomFilterContentComponent} from './src/components/filter/custom-filter-content.component';
import {OculosSolicitacaoListComponent} from './src/views/solicitacoes/oculos-solicitacao-list.component';
import { LeadsListComponent } from 'src/views/leads/leads-list.component';
import { LeadsModalComponent } from 'src/views/leads/modal/leads-modal.component';
import {LeadsModalObservacaoComponent} from './src/views/leads/modal/leads-modal-observacao.component';
import {MenuLateralComponent} from './src/components/menu-lateral/menu-lateral.component';
import {CardsInformativosComponent} from './src/components/cards-informativos/cards-informativos.component';
import {HomeLojistaComponent} from './src/views/home-lojista/home-lojista.component';
import {ProvadoresListComponent} from './src/views/provadores/provadores-list.component';
import {ProvadorModalComponent} from './src/views/provadores/provador-modal.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {OculosGlobaisModalComponent} from './src/views/oculos-globais/oculos-globais-modal.component';
import {OculosGlobaisListComponent} from './src/views/oculos-globais/oculos-globais-list.component';
import {RelatorioModalComponent} from './src/views/relatorios/relatorio-modal.component';
import {SafePipe} from './src/views/dashboard/safe-pipe';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenuTopBarComponent,
    ProjetosComponent,
    SobreNosComponent,
    ContatoComponent,
    HomeDesktopComponent,
    ProvadorCoreComponent,
    CameraComponent,
    DawnloadImageCameraComponent,
    StepperComponent,
    UploadImageComponent,
    MarkIrisImageComponent,
    SelecionaValoresProvadorModalComponent,
    PersonalizaProvadorComponent,

    AreaMedidasComponent,
    ProvadorDinamicoComponent,
    DialogOverviewExampleDialog,
    LoadingComponent,
    HttpResponseInterceptorComponent,
    LoginModalComponent,
    TopbarComponent,
    DashboardModalComponent,
    ListOculosComponent,
    FerramentaMedidasComponent,
    MoveOculosComponent,
    FerramentaCalibracaoComponent,
    RelatoriosListComponent,
    OculosListComponent,
    OculosModalComponent,
    DragdropUploadComponent,
    DragDirective,
    HiddenByPermissionDirective,
    HomeRevendaComponent,
    TenantListComponent,
    TenantModalComponent,
    PainelAdministrativoComponent,
    ProvadorConfigModalComponent,
    UsuarioModalComponent,
    PermissaoModalComponent,
    CustomFilterContentComponent,
    OculosSolicitacaoListComponent,
    LeadsListComponent,
    LeadsModalComponent,
    LeadsModalObservacaoComponent,
    MenuLateralComponent,
    CardsInformativosComponent,
    HomeLojistaComponent,
    ProvadoresListComponent,
    ProvadorModalComponent,
    OculosGlobaisModalComponent,
    OculosGlobaisListComponent,
    RelatorioModalComponent,
    SafePipe


  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatCardModule,
        MatDividerModule,
        MatChipsModule,
        MatToolbarModule,
        MatIconModule,
        FlexLayoutModule,
        MatTabsModule,
        MatButtonModule,
        MatDialogModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        FormsModule,
        ReactiveFormsModule,
        MatProgressBarModule,
        MatSidenavModule,
        MatProgressSpinnerModule,
        MatExpansionModule,
        MatMenuModule,
        MatGridListModule,
        MatStepperModule,
        OverlayPanelModule,
        DragDropModule,
        MatTableModule,
        MatPaginatorModule,
        MatSnackBarModule,
        MatSelectModule,
        ColorPickerModule,
        MatBadgeModule,
        MatTooltipModule,
        MatSlideToggleModule,


    ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [DialogOverviewExampleDialog,
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },

    MatSnackBar
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
