import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import * as _ from 'lodash';
import {AuthService} from '../../services/auth.service';
import {ProvadorBuilderService} from '../../services/provador-builder.service';
import {Provador} from '../../services/provador';
import {ColorPickerComponent} from '../../components/color-picker/color-picker.component';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {SnackMessageService} from '../../components/httpresponseinterceptor/snack-message-service';
import {ProvadorService} from '../../services/provador.service';


@Component({
  selector: 'provador-modal',
  templateUrl: './provador-modal.component.html',
  styleUrls: ['./provadores-list.component.css']

})
export class ProvadorModalComponent implements OnInit {

  builder: Provador = null;
  fileIsLoad = false;
  loadingImage = false;
  loading = false;
  public preview: any = null;
  public imagemCarregada = false;
  edit: boolean;
  viewLayout = true;
  toggleAvisos = false;
  toggleAvisosComoUtilizar = false;

  constructor(public dialogRef: MatDialogRef<any>,
              private authService: AuthService,
              @Inject(MAT_DIALOG_DATA) private data,
              private formBuilder: FormBuilder,
              private builderProvadorService: ProvadorBuilderService,
              private dialog: MatDialog,
              private snackService: SnackMessageService,
              private provadorService: ProvadorService,
  ) {

  }


  formLayout = this.formBuilder.group({
    'id': [''],
    'descricao': [''],
    'vigente': [''],
    'corDeFundo': [''],
    'logo': [''],
    'corTituloBemVindo': [''],
    'corCardExterno': [''],
    'corCardInterno': [''],
    'corSubtitulo': [''],
    'corInstrucoes': [''],
    'corIconesInstrucoes': [''],
    'corFundoBotaoAdicionarImagem': [''],
    'corTextoAdicionarImagem': [''],
    'corIconeAdicionarImagem': [''],
    'corTextoRetornarProduto': [''],
    'corIconeRetornarProduto': [''],
    'corFundoBotaoEnviarImagem': [''],
    'corTextoEnviarImagem': [''],
    'corIconeEnviarImagem': [''],
    'corFundoBotaoNovaImagem': [''],
    'corTextoNovaImagem': [''],
    'corIconeNovaImagem': [''],
    'corFundoBotaoContinuarCompra': [''],
    'corTextoContinuarCompra': [''],
    'corIconeContinuarCompra': [''],
  });

  ngOnInit() {
    if (this.data.bean != null) {
      this.formLayout.patchValue(this.data.bean);
      this.formLayout.patchValue({
        vigente: this.data.bean.vigente ? 'true' : 'false'
      });
    } else {
      this.formLayout.patchValue({
        vigente: 'false'
      });
    }
    this.builderLayout(this.data.bean);
    this.monitoraAlteracoes();

  }

  async save() {
    this.provadorService.save(this.formLayout.value);
  }

  toggleCollapseAvisos() {
    this.toggleAvisos = !this.toggleAvisos;
  }

  toggleCollapseComoUtilizar() {
    this.toggleAvisosComoUtilizar = !this.toggleAvisosComoUtilizar;
  }


  monitoraAlteracoes() {
    this.formLayout.get('corDeFundo').valueChanges.subscribe(value => {
      this.builder.corDeFundo = value;
    });
    this.formLayout.get('logo').valueChanges.subscribe(value => {
      this.builder.logo = value;
    });
    this.formLayout.get('corTituloBemVindo').valueChanges.subscribe(value => {
      this.builder.corTituloBemVindo = value;
    });
    this.formLayout.get('corCardExterno').valueChanges.subscribe(value => {
      this.builder.corCardExterno = value;
    });
    this.formLayout.get('corCardInterno').valueChanges.subscribe(value => {
      this.builder.corCardInterno = value;
    });
    this.formLayout.get('corSubtitulo').valueChanges.subscribe(value => {
      this.builder.corSubtitulo = value;
    });
    this.formLayout.get('corInstrucoes').valueChanges.subscribe(value => {
      this.builder.corInstrucoes = value;
    });
    this.formLayout.get('corIconesInstrucoes').valueChanges.subscribe(value => {
      this.builder.corIconesInstrucoes = value;
    });
    this.formLayout.get('corFundoBotaoAdicionarImagem').valueChanges.subscribe(value => {
      this.builder.corFundoBotaoAdicionarImagem = value;
    });
    this.formLayout.get('corTextoAdicionarImagem').valueChanges.subscribe(value => {
      this.builder.corTextoAdicionarImagem = value;
    });
    this.formLayout.get('corIconeAdicionarImagem').valueChanges.subscribe(value => {
      this.builder.corIconeAdicionarImagem = value;
    });
    this.formLayout.get('corTextoRetornarProduto').valueChanges.subscribe(value => {
      this.builder.corTextoRetornarProduto = value;
    });
    this.formLayout.get('corIconeRetornarProduto').valueChanges.subscribe(value => {
      this.builder.corIconeRetornarProduto = value;
    });
    this.formLayout.get('corFundoBotaoEnviarImagem').valueChanges.subscribe(value => {
      this.builder.corFundoBotaoEnviarImagem = value;
    });
    this.formLayout.get('corTextoEnviarImagem').valueChanges.subscribe(value => {
      this.builder.corTextoEnviarImagem = value;
    });
    this.formLayout.get('corIconeEnviarImagem').valueChanges.subscribe(value => {
      this.builder.corIconeEnviarImagem = value;
    });
    this.formLayout.get('corFundoBotaoNovaImagem').valueChanges.subscribe(value => {
      this.builder.corFundoBotaoNovaImagem = value;
    });
    this.formLayout.get('corTextoNovaImagem').valueChanges.subscribe(value => {
      this.builder.corTextoNovaImagem = value;
    });
    this.formLayout.get('corIconeNovaImagem').valueChanges.subscribe(value => {
      this.builder.corIconeNovaImagem = value;
    });
    this.formLayout.get('corFundoBotaoContinuarCompra').valueChanges.subscribe(value => {
      this.builder.corFundoBotaoContinuarCompra = value;
    });
    this.formLayout.get('corTextoContinuarCompra').valueChanges.subscribe(value => {
      this.builder.corTextoContinuarCompra = value;
    });
    this.formLayout.get('corIconeContinuarCompra').valueChanges.subscribe(value => {
      this.builder.corIconeContinuarCompra = value;
    });
  }

  onStepChange(event: StepperSelectionEvent) {
    const stepIndex = event.selectedIndex + 1; // Step index starts from 0
    this.setLayout(stepIndex);
  }

  onStepChangeForms(event: StepperSelectionEvent) {
    const stepIndex = event.selectedIndex + 1; // Step index starts from 0
    if (stepIndex === 1) {
      this.viewLayout = true;
    } else if (stepIndex === 2) {
      this.viewLayout = false;
    }
  }

  setLayout(tela: number) {
    switch (tela) {
      case 1:
        this.imagemCarregada = false;
        this.fileIsLoad = false;
        return;
      case 2:
        this.setDefaultParaEdicao();
        return;
      case 3:
        this.sendImage();
    }
  }

  openSelectedColorInput(fc: FormControl) {
    const dialogRef = this.dialog.open(ColorPickerComponent, {
      width: '400px',
      height: '500px',
      // position: {right: '0'}
    });

    dialogRef.afterClosed().subscribe(result => {
      fc.setValue(result);
    });
  }

  builderLayout(layout: any) {
    this.builder = this.builderProvadorService
      .withLogo(_.get(layout, 'logo'))
      .withCorDeFundo(_.get(layout, 'corDeFundo'))
      .withCorTituloBemVindo(_.get(layout, 'corTituloBemVindo'))
      .withCorCardExterno(_.get(layout, 'corCardExterno'))
      .withCorCardInterno(_.get(layout, 'corCardInterno'))
      .withCorSubtitulo(_.get(layout, 'corSubtitulo'))
      .withCorInstrucoes(_.get(layout, 'corInstrucoes'))
      .withCorIconesInstrucoes(_.get(layout, 'corIconesInstrucoes'))
      .withCorFundoBotaoAdicionarImagem(_.get(layout, 'corFundoBotaoAdicionarImagem'))
      .withCorTextoAdicionarImagem(_.get(layout, 'corTextoAdicionarImagem'))
      .withCorIconeAdicionarImagem(_.get(layout, 'corIconeAdicionarImagem'))
      .withCorTextoRetornarProduto(_.get(layout, 'corTextoRetornarProduto'))
      .withCorIconeRetornarProduto(_.get(layout, 'corIconeRetornarProduto'))
      .withCorFundoBotaoEnviarImagem(_.get(layout, 'corFundoBotaoEnviarImagem'))
      .withCorTextoEnviarImagem(_.get(layout, 'corTextoEnviarImagem'))
      .withCorIconeEnviarImagem(_.get(layout, 'corIconeEnviarImagem'))
      .withCorFundoBotaoNovaImagem(_.get(layout, 'corFundoBotaoNovaImagem'))
      .withCorTextoNovaImagem(_.get(layout, 'corTextoNovaImagem'))
      .withcorIconeNovaImagem(_.get(layout, 'corIconeNovaImagem'))
      .withCorFundoBotaoContinuarCompra(_.get(layout, 'corFundoBotaoContinuarCompra'))
      .withCorTextoContinuarCompra(_.get(layout, 'corTextoContinuarCompra'))
      .withcorIconeContinuarCompra(_.get(layout, 'corIconeContinuarCompra'))
      .build();
  }


  setDefaultParaEdicao() {
    this.preview = './assets/notfound.jpg';
    this.fileIsLoad = true;
    this.imagemCarregada = false;
  }

  async sendImage() {
    this.preview = './assets/notfound.jpg';
    this.loadingImage = false;
    this.imagemCarregada = true;
    return;
  }

  close() {
    this.dialogRef.close();
  }

  abrirOpcaoEstilo(e) {
    e.stopPropagation();
    this.edit = !this.edit;
  }

}
