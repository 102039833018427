import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../environments/environment';
import {ProvadorService} from '../../services/provador.service';
import {Router} from '@angular/router';
import {SnackMessageService} from '../httpresponseinterceptor/snack-message-service';
import {DomSanitizer} from '@angular/platform-browser';

import {Provador} from '../../services/provador';
import * as _ from 'lodash';

@Component({
  selector: 'app-provador-core',
  templateUrl: './provador-core.component.html',
  styleUrls: ['./provador-core.component.css']
})
export class ProvadorCoreComponent implements OnInit {

  fileIsLoad = false;
  loadingImage = false;
  loading = false;
  srcImageDisplay: string;
  selectedFile: ImageSnippet;

  image: File = null;
  // pagina

  @Input() builder: Provador;
  @Input() domain: String;
  public glasse: string;
  public preview: any = null;
  public imagemCarregada = false;

  constructor(private siveonService: ProvadorService,
              private route: Router,
              private snackService: SnackMessageService,
              private sanitizer: DomSanitizer
  ) {
  }

  async ngOnInit() {
    await this.siveonService.incrementAcesso(this.builder.codigoOculos, this.domain);
    // if (this.ativo === 'false') {
    //   await this.route.navigate(['/']);
    // }
  }

  showErroNoProcessamento() {
    this.snackService.showErrorMessage('Não foi possivel realizar o processamento da imagem!');
    this.preview = './assets/notfound.jpg';
    this.loadingImage = false;
    this.imagemCarregada = true;
  }

  async sendImage() {
    this.loadingImage = true;
    const formData = new FormData();
    formData.append('image', this.image);
    formData.append('codigoOculos', this.builder.codigoOculos);
    const url = environment.apiSiveonDev + '/api/siveon/provador';
    this.siveonService.sendImageProvadorSiveon(url, formData)
      .toPromise()
      .then(response => {
        if (_.isUndefined(response)) {
          this.showErroNoProcessamento();
          return;
        }
        this.loadingImage = false;
        this.imagemCarregada = true;
        this.preview = 'data:image/png;base64,' + response;
        this.snackService.showSuccessMessage('Imagem processada com sucesso!');
      }).catch(error => {
      this.showErroNoProcessamento();
    });
  }


  close() {
    window.location.reload();
  }

  onFileUpload(event: any) {
    const file: File = event.target.files[0];
    this.preview = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
    const reader = new FileReader();
    // tslint:disable-next-line:no-shadowed-variable
    reader.addEventListener('load', (event: any) => {
      this.selectedFile = new ImageSnippet(event.target.result, file);
      this.image = this.selectedFile.file;
    });
    reader.readAsDataURL(file);
    this.fileIsLoad = true;
  }
}


class ImageSnippet {
  constructor(public src: string, public file: File) {
  }
}

