import {Directive, ElementRef, Input, OnInit,} from '@angular/core';
import {PermissaoService} from '../services/permissao.service';
import * as _ from 'lodash';


@Directive({
  selector: '[hiddenByPermission]'
})
export class HiddenByPermissionDirective implements OnInit {
  @Input()
  private permissions: any;

  @Input()
  private tags: string[];

  @Input()
  private exec: string[] = ['hidden'];

  constructor(private elementRef: ElementRef,
              private permissaoService: PermissaoService,
  ) {

  }


  async ngOnInit() {
    // check all tags, if not exist a tag in array of the tags permissions, hidden element
    const hasPermission = this.checkExistPermission();
    // if exec is over, and not exist a permission,display message in component
    if (this.exec.includes('over') && !hasPermission) {
      const span = document.createElement('span');
      span.id = 'hidden-message';
      span.innerText = 'Você não tem permissão para acessar esta funcionalidade!';
      //add element in span a actualy element
      this.elementRef.nativeElement.parentNode.insertBefore(span, this.elementRef.nativeElement.nextSibling);
    }
    // kill element html
    if (!hasPermission) {
      this.elementRef.nativeElement.remove();
    }
  }

  private checkExistPermission(): boolean {
    if (!_.isEmpty(this.permissions)) {
      return this.tags.some(tag => this.permissions.includes(tag));
    }

  }


}
